import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Ticket, Users, ShipWheel, Activity, Settings, TrendingUp, AlertTriangle, User2, Radio, Clock, Star, Trophy, Users2, Watch } from 'lucide-react';
import { API_URL } from './api_config';

interface DashboardCardProps {
  title: string;
  value: string | number | null;
  icon: React.ReactNode;
  color: string;
  isLoading?: boolean;
}

interface StreamHistory {
  streamerName: string;
  duration: string;
  peakViewers: number;
  avgViewers: number;
  uniqueChatters: number;
  endedAt: string;
}

interface Winner {
  id: string;
  username: string;
  amount: number;
  createdAt: string;
}

interface ApiResponse {
  status: string;
  data: {
    total?: number;
    totalValue?: number;
    drawCount?: number;
  };
}


interface StatisticCardProps {
  icon: React.ReactNode;
  label: string;
  value: string | number;
  change: string;
  isPositive: boolean;
}


const LiveHistory: React.FC = () => {
  const [streamHistory, setStreamHistory] = useState<StreamHistory[]>([
    {
      streamerName: "KGYTB",
      duration: "1h 32m",
      peakViewers: 217,
      avgViewers: 187,
      uniqueChatters: 44,
      endedAt: "2024-02-09T15:30:00Z"
    }
  ]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6 col-span-full"
    >
      <div className="flex items-center gap-4 mb-6">
        <div className="p-3 bg-purple-500/10 rounded-xl">
          <Watch className="w-6 h-6 text-purple-400" />
        </div>
        <div>
          <h2 className="text-xl font-bold text-white">Live Stream History</h2>
          <p className="text-gray-400 text-sm">Past 24 hours stream statistics</p>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-gray-400 text-sm">
              <th className="text-left pb-4">Streamer</th>
              <th className="text-left pb-4">Duration</th>
              <th className="text-left pb-4">Peak Viewers</th>
              <th className="text-left pb-4">Avg. Viewers</th>
              <th className="text-left pb-4">Unique Chatters</th>
              <th className="text-left pb-4">Ended</th>
            </tr>
          </thead>
          <tbody>
            {streamHistory.map((stream, index) => (
              <tr 
                key={index}
                className="border-t border-gray-800/50"
              >
                <td className="py-4">
                  <div className="flex items-center gap-3">
                    <div className="w-8 h-8 rounded-full bg-violet-500/20 flex items-center justify-center">
                      <Users2 className="w-4 h-4 text-violet-400" />
                    </div>
                    <span className="text-white font-medium">{stream.streamerName}</span>
                  </div>
                </td>
                <td className="py-4">
                  <div className="flex items-center gap-2">
                    <Clock className="w-4 h-4 text-blue-400" />
                    <span className="text-gray-300">{stream.duration}</span>
                  </div>
                </td>
                <td className="py-4">
                  <div className="flex items-center gap-2">
                    <Star className="w-4 h-4 text-yellow-400" />
                    <span className="text-gray-300">{stream.peakViewers.toLocaleString()}</span>
                  </div>
                </td>
                <td className="py-4">
                  <span className="text-gray-300">{stream.avgViewers.toLocaleString()}</span>
                </td>
                <td className="py-4">
                  <span className="text-gray-300">{stream.uniqueChatters.toLocaleString()}</span>
                </td>
                <td className="py-4">
                  <span className="text-gray-400">{timeAgo(stream.endedAt)}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
};

const StatisticCard: React.FC<StatisticCardProps> = ({ icon, label, value, change, isPositive }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6"
  >
    <div className="flex items-center gap-4 mb-3">
      <div className="p-3 bg-violet-500/10 rounded-xl">
        {icon}
      </div>
      <span className="text-gray-400">{label}</span>
    </div>
    <div className="flex items-end justify-between">
      <span className="text-2xl font-bold text-white">{value}</span>
      <div className={`flex items-center gap-1 text-sm ${isPositive ? 'text-green-400' : 'text-red-400'}`}>
        {isPositive ? '↑' : '↓'} {change}%
      </div>
    </div>
  </motion.div>
);


const StreamStats = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6"
  >
    <div className="flex items-center gap-4 mb-6">
      <div className="p-3 bg-red-500/10 rounded-xl">
        <Radio className="w-6 h-6 text-red-400" />
      </div>
      <div>
        <h2 className="text-xl font-bold text-white">Live Stream Info</h2>
        <p className="text-gray-400 text-sm">Current stream statistics</p>
      </div>
    </div>
    <div className="grid grid-cols-2 gap-4">
      <div className="p-4 bg-gray-800/50 rounded-lg">
        <div className="flex items-center gap-2 mb-2">
          <Clock className="w-4 h-4 text-blue-400" />
          <span className="text-gray-400 text-sm">Stream Duration</span>
        </div>
        <span className="text-xl font-bold text-white">Na/Na</span>
      </div>
      <div className="p-4 bg-gray-800/50 rounded-lg">
        <div className="flex items-center gap-2 mb-2">
          <Star className="w-4 h-4 text-yellow-400" />
          <span className="text-gray-400 text-sm">Peak Viewers</span>
        </div>
        <span className="text-xl font-bold text-white">Na/Na</span>
      </div>
    </div>
  </motion.div>
);


const timeAgo = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
  if (diffInSeconds < 3600) {
    return `${Math.floor(diffInSeconds / 60)} hours ago`;
  } else if (diffInSeconds < 86400) {
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  } else {
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  }
};

const TopWinners: React.FC = () => {
  const [winners, setWinners] = useState<Winner[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchWinners = async () => {
      try {
        const response = await fetch(`${API_URL}/winners/winners`);
        const data = await response.json();
        
        if (Array.isArray(data)) {
          const recentWinners = data.filter((winner) => {
            const createdAt = new Date(winner.createdAt);
            return (new Date().getTime() - createdAt.getTime()) / (1000 * 60 * 60 * 24) < 7;
          });
          setWinners(recentWinners);
        }
      } catch (error) {
        console.error('Error fetching winners:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWinners();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6"
    >
      <div className="flex items-center gap-4 mb-6">
        <div className="p-3 bg-yellow-500/10 rounded-xl">
          <Trophy className="w-6 h-6 text-yellow-400" />
        </div>
        <div>
          <h2 className="text-xl font-bold text-white">Top Winners Last Days</h2>
          <p className="text-gray-400 text-sm">Winners of EmpireDeal & other games</p>
        </div>
      </div>
      <div className="space-y-4">
        {isLoading ? (
          <p className="text-gray-400">Loading...</p>
        ) : winners.length > 0 ? (
          winners.map((winner, index) => (
            <div key={winner.id} className="flex items-center justify-between p-3 bg-gray-800/50 rounded-lg">
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full bg-violet-500/20 flex items-center justify-center text-violet-400">
                  #{index + 1}
                </div>
                <span className="text-white">{winner.username}</span>
              </div>
              <div className="flex items-center gap-4">
                <span className="text-emerald-400 font-bold">{winner.amount}€</span>
                <span className="text-gray-500 text-sm">{timeAgo(winner.createdAt)}</span>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-400">No recent winners</p>
        )}
      </div>
    </motion.div>
  );
};



const WeeklyActivityChart: React.FC = () => {
  const chartRef = useRef<HTMLDivElement>(null);
  const days: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  
  const generateActivityData = (): number[] => {
    const baseData = [45, 60, 75, 65, 80, 70, 90];
    return baseData.map(base => base + Math.random() * 20);
  };

  const activityData = generateActivityData();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6"
    >
      <div className="flex items-center gap-4 mb-6">
        <div className="p-3 bg-violet-500/10 rounded-xl">
          <TrendingUp className="w-6 h-6 text-violet-400" />
        </div>
        <div>
          <h2 className="text-xl font-bold text-white">Weekly Activity</h2>
          <p className="text-gray-400 text-sm">User engagement over time</p>
        </div>
      </div>

      <div ref={chartRef} className="h-64 flex items-end justify-between relative">
        <svg 
          className="absolute top-0 left-0 w-full h-full pointer-events-none z-0" 
          viewBox="0 0 700 300"
        >
          <path 
            d={`M 0 ${300 - activityData[0] * 2} Q 100 ${300 - (activityData[0] + activityData[1]) * 1.5}, 200 ${300 - activityData[2] * 2} T 400 ${300 - activityData[4] * 2} T 600 ${300 - activityData[6] * 2}`}
            fill="none"
            stroke="rgba(139, 92, 246, 0.2)"
            strokeWidth="3"
          />
        </svg>

        {days.map((day, index) => (
          <div key={day} className="flex flex-col items-center z-10 relative">
            <div 
              className={`w-12 rounded-t-md transition-all duration-500 ${
                day === 'Sun' 
                  ? 'bg-gradient-to-t from-violet-600 to-violet-400 scale-110 shadow-lg shadow-violet-500/20' 
                  : 'bg-gradient-to-t from-gray-700 to-gray-600'
              }`}
              style={{ height: `${activityData[index]}%` }}
            >
              <div className="absolute w-3 h-3 rounded-full -top-2 left-1/2 -translate-x-1/2 bg-violet-400 shadow-lg shadow-violet-500/30" />
            </div>
            <span className={`text-sm mt-2 ${
              day === 'Sun' ? 'text-violet-400 font-semibold' : 'text-gray-400'
            }`}>{day}</span>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

const DashboardCard: React.FC<DashboardCardProps> = ({ title, value, icon, color, isLoading }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl overflow-hidden"
  >
    <div className={`p-6 border-l-4 ${color}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className={`p-3 rounded-xl ${color.replace('border-', 'bg-')}/10`}>
            {icon}
          </div>
          <div className="ml-4">
            <h3 className="text-gray-400 text-sm">{title}</h3>
            {isLoading ? (
              <div className="h-6 w-24 bg-gray-700 rounded animate-pulse mt-1" />
            ) : (
              <p className="text-2xl font-bold text-white">{value}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  </motion.div>
);

const CustomAlert = () => (
  <motion.div 
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    className="mb-6 p-4 rounded-lg border border-yellow-500/50 bg-yellow-500/10 flex items-center gap-3"
  >
    <AlertTriangle className="h-5 w-5 text-yellow-500" />
    <p className="text-yellow-200">
      Statistics are currently in beta. A comprehensive update will be pushed soon to improve accuracy and add new metrics.
    </p>
  </motion.div>
);

const MainContent: React.FC = () => {
  const [promoCodeCount, setPromoCodeCount] = useState<number | null>(null);
  const [promoCodeValue, setPromoCodeValue] = useState<number | null>(null);
  const [drawValue, setDrawValue] = useState<number | null>(null);
  const [viewersCount, setViewersCount] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [promoResponse, valueResponse, drawResponse, viewersResponse] = await Promise.all([
          fetch(API_URL + '/promo-codes/count'),
          fetch(API_URL + '/promo-codes/count-value'),
          fetch(API_URL + '/game/drawCount'),
          fetch(API_URL + '/viewers/viewers-count')
        ]);

        const promoData: ApiResponse = await promoResponse.json();
        const valueData: ApiResponse = await valueResponse.json();
        const drawData: ApiResponse = await drawResponse.json();
        const viewersData = await viewersResponse.json();

        setPromoCodeCount(promoData.data.total || 0);
        setPromoCodeValue(valueData.data.totalValue || 0);
        setDrawValue(drawData.data.drawCount || 0);
        setViewersCount(viewersData.count || 0);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-violet-900/20 via-gray-900 to-black p-6">
      <div className="max-w-7xl mx-auto">
        <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-violet-950/50 to-gray-900/50 p-8 backdrop-blur-xl border border-gray-800/50 mb-8">
          <div className="absolute inset-0 bg-grid-white/[0.02] -z-10" />
          <div className="flex items-center gap-4">
            <div className="p-3 bg-violet-500/10 rounded-2xl ring-1 ring-violet-500/20">
              <Settings className="w-8 h-8 text-violet-400" />
            </div>
            <div>
              <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                Dashboard Overview
              </h1>
              <p className="text-gray-400 text-sm mt-1">
                Monitor your platform's performance
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <DashboardCard 
            title="Total Promo Codes" 
            value={promoCodeCount ? `${promoCodeCount.toLocaleString()}` : null}
            icon={<Ticket className="text-violet-400" size={24} />} 
            color="border-violet-500"
            isLoading={isLoading}
          />
          <DashboardCard 
            title="Total € Dropped" 
            value={promoCodeValue ? `${promoCodeValue.toLocaleString()}€` : null}
            icon={<Users className="text-emerald-400" size={24} />} 
            color="border-emerald-500"
            isLoading={isLoading}
          />
          <DashboardCard 
            title="Total Draw" 
            value={drawValue}
            icon={<ShipWheel className="text-blue-400" size={24} />} 
            color="border-blue-500"
            isLoading={isLoading}
          />
          <DashboardCard 
            title="Viewers" 
            value={viewersCount !== null ? viewersCount.toLocaleString() : null}
            icon={<User2 className="text-red-400" size={24} />} 
            color="border-red-500"
            isLoading={isLoading}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          <StatisticCard
            icon={<Users className="w-6 h-6 text-blue-400" />}
            label="New Users Today"
            value="0"
            change="0"
            isPositive={true}
          />
          <StatisticCard
            icon={<Activity className="w-6 h-6 text-green-400" />}
            label="Active Users"
            value="1"
            change="100"
            isPositive={true}
          />
          <StatisticCard
            icon={<Radio className="w-6 h-6 text-red-400" />}
            label="Live Streams"
            value="3"
            change="2.1"
            isPositive={false}
          />
        </div>

        {/* Additional widgets */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <StreamStats />
          <TopWinners />
        </div>
        <br/>
        <div className="grid grid-cols-1 gap-6">
          <LiveHistory />
        </div>
      </div>
    </div>
  );
};

export default MainContent;