import React, { useState, useEffect } from 'react';
import { PlusCircle, Search, Trash2 } from 'lucide-react';
import { API_URL } from 'components/api_config';

interface Winner {
  id: number;
  username: string;
  amount: number;
  gameType: string;
  empiredrop_id: string;
  createdAt: string;
}

const WinnersTable: React.FC = () => {
  const [winners, setWinners] = useState<Winner[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newWinner, setNewWinner] = useState({
    username: '',
    amount: '',
    gameType: 'Empire Deal',
    empiredrop_id: '',
  });


  useEffect(() => {
    fetchWinners();
  }, []);

  const fetchWinners = async () => {
    try {
      const response = await fetch(API_URL + "/winners/winners");
      const data = await response.json();
      setWinners(data);
    } catch (error) {
      console.error('Erreur lors du chargement des winners:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newWinner.username || !newWinner.amount || !newWinner.empiredrop_id) return;

    try {
      const response = await fetch(API_URL  + "/winners/winners", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: newWinner.username,
          amount: Number(newWinner.amount),
          gameType: newWinner.gameType, // ✅ Correction ici
          empiredrop_id: newWinner.empiredrop_id,
        }),
      });

      if (!response.ok) throw new Error('Erreur lors de l’ajout du gagnant');

      const addedWinner = await response.json();
      setWinners([addedWinner.winner, ...winners]);
      setNewWinner({ username: '', amount: '', gameType: 'Empire Deal', empiredrop_id: '' });
      setIsModalOpen(false);
    } catch (error) {
      console.error('Erreur lors de l’ajout du gagnant:', error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      const response = await fetch(`${API_URL}/winners/winners/${id}`, { method: 'DELETE' });
      if (!response.ok) throw new Error('Erreur lors de la suppression');

      setWinners(winners.filter((winner) => winner.id !== id));
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
    }
  };

  return (
    <div className="min-h-screen bg-[#0f1116] p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <div className="relative flex-1 mr-4">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search by username..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-gray-800/50 border border-gray-700 rounded-lg text-gray-200 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
          </div>

          <button
            onClick={() => setIsModalOpen(true)}
            className="px-4 py-2 bg-violet-600 hover:bg-violet-700 text-white rounded-lg flex items-center gap-2 transition-colors"
          >
            <PlusCircle className="w-5 h-5" />
            Add Winner
          </button>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
            <div className="bg-gray-900 p-6 rounded-lg w-full max-w-md">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-gray-200">Add New Winner</h2>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="text-gray-400 hover:text-gray-200"
                >
                  ✕
                </button>
              </div>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-1">Username</label>
                  <input
                    type="text"
                    value={newWinner.username}
                    onChange={(e) => setNewWinner({ ...newWinner, username: e.target.value })}
                    className="w-full px-4 py-2 bg-gray-800/50 border border-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring-2 focus:ring-violet-500"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-1">Amount</label>
                  <input
                    type="number"
                    value={newWinner.amount}
                    onChange={(e) => setNewWinner({ ...newWinner, amount: e.target.value })}
                    className="w-full px-4 py-2 bg-gray-800/50 border border-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring-2 focus:ring-violet-500"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-1">Game Type</label>
                  <select
                    value={newWinner.gameType}
                    onChange={(e) => setNewWinner({ ...newWinner, gameType: e.target.value })}
                    className="w-full px-4 py-2 bg-gray-800/50 border border-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring-2 focus:ring-violet-500"
                  >
                    <option value="Empire Deal">Empire Deal</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-1">EmpireDrop ID</label>
                  <input
                    type="text"
                    value={newWinner.empiredrop_id}
                    onChange={(e) => setNewWinner({ ...newWinner, empiredrop_id: e.target.value })}
                    className="w-full px-4 py-2 bg-gray-800/50 border border-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring-2 focus:ring-violet-500"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full py-2 bg-violet-600 hover:bg-violet-700 text-white rounded-lg transition-colors"
                >
                  Add Winner
                </button>
              </form>
            </div>
          </div>
        )}

        <div className="overflow-x-auto rounded-lg border border-gray-800">
          <table className="w-full">
            <thead className="bg-gray-800/50">
              <tr>
                <th className="py-3 px-6 text-left text-sm text-gray-400">User</th>
                <th className="py-3 px-6 text-left text-sm text-gray-400">Amount</th>
                <th className="py-3 px-6 text-left text-sm text-gray-400">Game Type</th>
                <th className="py-3 px-6 text-left text-sm text-gray-400">EmpireDrop ID</th>
                <th className="py-3 px-6 text-left text-sm text-gray-400">Date</th>
                <th className="py-3 px-6 text-left text-sm text-gray-400">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-800">
              {winners.map((winner) => (
                <tr key={winner.id} className="bg-gray-900/50">
                  <td className="py-3 px-6 text-gray-200">{winner.username}</td>
                  <td className="py-3 px-6 text-green-400">${winner.amount.toLocaleString()}</td>
                  <td className="py-3 px-6 text-violet-400">{winner.gameType}</td>
                  <td className="py-3 px-6 text-gray-400">{winner.empiredrop_id}</td>
                  <td className="py-3 px-6 text-gray-400">{new Date(winner.createdAt).toLocaleString()}</td>
                  <td className="py-3 px-6">
                    <button onClick={() => handleDelete(winner.id)}>
                      <Trash2 className="w-5 h-5 text-red-500 hover:text-red-700" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WinnersTable;
