import React, { useState, useEffect } from "react";
import {
  Trophy,
  Trash2,
  ChevronLeft,
  ChevronRight,
  Download,
} from "lucide-react";
import { API_URL } from "components/api_config";
import { motion } from "framer-motion";

interface WinnerHistory {
  id: number;
  winnerName: string;
  pfp: string;
  timestamp: string;
  isActive: boolean;
}

export const WinnerHistory: React.FC = () => {
  const [winners, setWinners] = useState<WinnerHistory[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  const totalPages = Math.ceil(winners.length / ITEMS_PER_PAGE);
  const paginatedWinners = winners.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const downloadCSV = () => {
    const headers = ["ID", "Winner Name", "Profile Picture", "Timestamp"];
    const csvContent = [
      headers.join(","),
      ...winners.map((winner) =>
        [
          winner.id,
          winner.winnerName,
          winner.pfp,
          new Date(winner.timestamp).toLocaleString(),
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "winners-history.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const fetchWinners = async () => {
      try {
        const response = await fetch(API_URL + "/history-winner");
        if (!response.ok) throw new Error("Failed to fetch winners");
        const data = await response.json();
        setWinners(data);
      } catch (err) {
        console.error("Error fetching winners:", err);
        setError("Failed to load winners");
      } finally {
        setIsLoading(false);
      }
    };

    fetchWinners();
    const intervalId = setInterval(fetchWinners, 4000);
    return () => clearInterval(intervalId);
  }, []);

  const handleDelete = async (id: number) => {
    try {
      const response = await fetch(`${API_URL}/history-winner/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) throw new Error("Failed to delete winner");

      setWinners((prev) => prev.filter((winner) => winner.id !== id));
      const newTotalPages = Math.ceil((winners.length - 1) / ITEMS_PER_PAGE);
      if (currentPage > newTotalPages)
        setCurrentPage(Math.max(1, newTotalPages));
    } catch (err) {
      console.error("Error deleting winner:", err);
    }
  };

  if (isLoading) {
    return (
      <div className="p-6 bg-[#1A1C23] rounded-xl shadow-lg">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-700 rounded w-1/3 mb-8"></div>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-20 bg-gray-700 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-[#1A1C23] rounded-xl border border-gray-800/50">
        <div className="flex items-center justify-center text-red-400 py-2">
          <span>{error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <div className="p-2 bg-violet-500/10 rounded-xl">
            <Trophy className="w-6 h-6 text-violet-400" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-white">Winner History</h2>
            <p className="text-sm text-gray-400">
              {winners.length} winner{winners.length !== 1 ? "s" : ""}
            </p>
          </div>
        </div>
        <motion.button
          onClick={downloadCSV}
          className="flex items-center gap-2 px-4 py-2 bg-violet-500/20 text-violet-400 
            rounded-lg hover:bg-violet-500/30 transition-colors"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Download size={18} />
          Download CSV
        </motion.button>
      </div>

      {/* Winners List */}
      <div className="space-y-4">
        {paginatedWinners.map((winner) => (
          <div
            key={winner.id}
            className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-4"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="w-12 h-12 rounded-lg overflow-hidden border border-violet-500/20">
                  <img
                    src={winner.pfp}
                    alt={winner.winnerName}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <div className="text-white font-medium">
                    {winner.winnerName}
                  </div>
                  <div className="text-sm text-gray-400">
                    {new Date(winner.timestamp).toLocaleString()}
                  </div>
                </div>
              </div>
              <button
                onClick={() => handleDelete(winner.id)}
                className="p-2 text-red-400 hover:bg-red-500/20 rounded-lg transition-colors"
              >
                <Trash2 size={20} />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex items-center justify-between mt-6">
          <button
            onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
            disabled={currentPage === 1}
            className={`p-2 rounded-lg ${
              currentPage === 1
                ? "text-gray-500 cursor-not-allowed"
                : "text-violet-400 hover:bg-violet-500/20"
            }`}
          >
            <ChevronLeft size={20} />
          </button>

          <div className="flex items-center justify-center flex-1 space-x-2">
            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
              const pageNum = i + 1;
              return (
                <button
                  key={pageNum}
                  onClick={() => setCurrentPage(pageNum)}
                  className={`w-8 h-8 rounded-lg ${
                    currentPage === pageNum
                      ? "bg-violet-500 text-white"
                      : "text-violet-400 hover:bg-violet-500/20"
                  }`}
                >
                  {pageNum}
                </button>
              );
            })}
            {totalPages > 5 && <span className="text-gray-400">...</span>}
          </div>

          <button
            onClick={() => setCurrentPage((p) => Math.min(totalPages, p + 1))}
            disabled={currentPage === totalPages}
            className={`p-2 rounded-lg ${
              currentPage === totalPages
                ? "text-gray-500 cursor-not-allowed"
                : "text-violet-400 hover:bg-violet-500/20"
            }`}
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default WinnerHistory;
