import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Settings,
  Moon,
  Sun,
  Bell,
  Shield,
  Globe,
  Palette,
  Volume2,
  Mail,
  ToggleLeft,
  Check,
  ChevronRight,
  Lock,
  Loader2,
} from 'lucide-react';
import { API_URL } from './api_config';

interface SettingsOption {
  name: string;
  description: string;
  enabled: boolean;
  icon: React.ReactNode;
  category: string;
}

export const SettingsPanel: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState('general');
  const [settings, setSettings] = useState<SettingsOption[]>([
    {
      name: 'Dark Mode',
      description: 'Switch between light and dark themes',
      enabled: true,
      icon: <Moon className="text-[#611BE2]" size={22} />,
      category: 'appearance'
    },
    {
      name: 'Notifications',
      description: 'Enable push notifications',
      enabled: true,
      icon: <Bell className="text-[#611BE2]" size={22} />,
      category: 'general'
    },
    {
      name: 'Sound Effects',
      description: 'Play sounds on actions',
      enabled: false,
      icon: <Volume2 className="text-[#611BE2]" size={22} />,
      category: 'general'
    },
    {
      name: 'Security Alerts',
      description: 'Get notified about security events',
      enabled: true,
      icon: <Shield className="text-[#611BE2]" size={22} />,
      category: 'security'
    },
    {
      name: 'Email Updates',
      description: 'Receive email notifications',
      enabled: false,
      icon: <Mail className="text-[#611BE2]" size={22} />,
      category: 'notifications'
    },
    {
      name: 'Language',
      description: 'Change interface language',
      enabled: true,
      icon: <Globe className="text-[#611BE2]" size={22} />,
      category: 'general'
    },
    {
      name: 'Custom Theme',
      description: 'Use custom color scheme',
      enabled: false,
      icon: <Palette className="text-[#611BE2]" size={22} />,
      category: 'appearance'
    },
  ]);

  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');

  const categories = [
    { id: 'general', name: 'General', icon: <Settings size={20} /> },
    { id: 'security', name: 'Security', icon: <Shield size={20} /> },
    { id: 'notifications', name: 'Notifications', icon: <Bell size={20} /> },
  ];

  const toggleSetting = (settingName: string) => {
    setSettings(settings.map(setting => 
      setting.name === settingName 
        ? { ...setting, enabled: !setting.enabled }
        : setting
    ));
  };

  const simulatePasswordChange = async () => {
    setPasswordError('');
    setPasswordSuccess('');
  
    if (!currentPassword || !newPassword || !confirmPassword) {
      setPasswordError('Please fill in all fields');
      return;
    }
  
    if (newPassword !== confirmPassword) {
      setPasswordError('The new passwords do not match');
      return;
    }
  
    if (newPassword.length < 8) {
      setPasswordError('The new password must be at least 8 characters long');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const response = await fetch(API_URL +  '/change-password', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Ajouter un token si nécessaire
        },
        body: JSON.stringify({
          currentPassword,
          newPassword,
        }),
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        setPasswordError(result.message || 'Failed to change password');
        return;
      }
  
      setPasswordSuccess('Your password has been updated successfully 🎉');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
  
      setTimeout(() => {
        setShowPasswordForm(false);
        setPasswordSuccess('');
      }, 2000);
    } catch (error) {
      setPasswordError('An error occurred while changing your password');
    } finally {
      setIsLoading(false);
    }
  };

  const renderPasswordForm = () => (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      className="mt-4 space-y-4"
    >
      <input
        type="password"
        placeholder="Current password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        className="w-full p-2 bg-[#1A1C23] border border-[#3D4149] rounded-lg text-white"
      />
      <input
        type="password"
        placeholder="New password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        className="w-full p-2 bg-[#1A1C23] border border-[#3D4149] rounded-lg text-white"
      />
      <input
        type="password"
        placeholder="Confirm your new password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        className="w-full p-2 bg-[#1A1C23] border border-[#3D4149] rounded-lg text-white"
      />
      {passwordError && (
        <p className="text-red-500 text-sm">{passwordError}</p>
      )}
      {passwordSuccess && (
        <p className="text-green-500 text-sm">{passwordSuccess}</p>
      )}
      <motion.button
        onClick={simulatePasswordChange}
        disabled={isLoading}
        className="w-full bg-[#611BE2] text-white rounded-lg p-2 flex items-center justify-center"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        {isLoading ? (
          <Loader2 className="animate-spin" size={20} />
        ) : (
          'Change your password'
        )}
      </motion.button>
    </motion.div>
  );

  const renderSecuritySection = () => (
    <>
      {settings
        .filter(setting => setting.category === activeCategory)
        .map((setting, index) => (
          <motion.div
            key={setting.name}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
            className="bg-[#2C3038] p-6 rounded-xl border-2 border-[#3D4149]"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                {setting.icon}
                <div>
                  <h3 className="text-white font-medium">{setting.name}</h3>
                  <p className="text-gray-400 text-sm">{setting.description}</p>
                </div>
              </div>
              <motion.button
                onClick={() => toggleSetting(setting.name)}
                className={`w-12 h-6 rounded-full relative ${
                  setting.enabled ? 'bg-[#611BE2]' : 'bg-gray-600'
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <motion.div
                  className="w-5 h-5 bg-white rounded-full absolute top-0.5 left-0.5"
                  animate={{
                    x: setting.enabled ? 24 : 0,
                  }}
                  transition={{ type: "spring", stiffness: 500, damping: 30 }}
                />
              </motion.button>
            </div>
          </motion.div>
        ))}
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className="bg-[#2C3038] p-6 rounded-xl border-2 border-[#3D4149]"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Lock className="text-[#611BE2]" size={22} />
            <div>
              <h3 className="text-white font-medium">Change your password</h3>
              <p className="text-gray-400 text-sm">Update your current password</p>
            </div>
          </div>
          <motion.button
            onClick={() => setShowPasswordForm(!showPasswordForm)}
            className="text-[#611BE2] hover:text-[#7B3AE7]"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {showPasswordForm ? 'Cancel' : 'Edit'}
          </motion.button>
        </div>
        <AnimatePresence>
          {showPasswordForm && renderPasswordForm()}
        </AnimatePresence>
      </motion.div>
    </>
  );

  return (
    <motion.div
      className="p-8 bg-gradient-to-br from-[#1A1C23] to-[#252A34] rounded-2xl shadow-2xl"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center mb-8">
        <Settings className="mr-3 text-[#611BE2]" size={28} />
        <h2 className="text-2xl font-bold text-white">Settings</h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        {/* Categories Sidebar */}
        <div className="lg:col-span-1">
          <div className="space-y-2">
            {categories.map((category) => (
              <motion.button
                key={category.id}
                onClick={() => setActiveCategory(category.id)}
                className={`w-full flex items-center space-x-3 p-4 rounded-xl transition-all ${
                  activeCategory === category.id
                    ? 'bg-[#611BE2] text-white'
                    : 'bg-[#2C3038] text-gray-400 hover:bg-[#3D4149]'
                }`}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                {category.icon}
                <span className="font-medium">{category.name}</span>
                <ChevronRight 
                  size={18} 
                  className={`ml-auto transition-transform ${
                    activeCategory === category.id ? 'rotate-90' : ''
                  }`}
                />
              </motion.button>
            ))}
          </div>
        </div>

        {/* Settings Content */}
        <div className="lg:col-span-3">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeCategory}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
              className="space-y-4"
            >
              {activeCategory === 'security' ? renderSecuritySection() : (
                settings
                  .filter(setting => setting.category === activeCategory)
                  .map((setting, index) => (
                    <motion.div
                      key={setting.name}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                      className="bg-[#2C3038] p-6 rounded-xl border-2 border-[#3D4149]"
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          {setting.icon}
                          <div>
                            <h3 className="text-white font-medium">{setting.name}</h3>
                            <p className="text-gray-400 text-sm">{setting.description}</p>
                          </div>
                        </div>
                        <motion.button
                          onClick={() => toggleSetting(setting.name)}
                          className={`w-12 h-6 rounded-full relative ${
                            setting.enabled ? 'bg-[#611BE2]' : 'bg-gray-600'
                          }`}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <motion.div
                            className="w-5 h-5 bg-white rounded-full absolute top-0.5 left-0.5"
                            animate={{
                              x: setting.enabled ? 24 : 0,
                            }}
                            transition={{ type: "spring", stiffness: 500, damping: 30 }}
                          />
                        </motion.button>
                      </div>
                    </motion.div>
                  ))
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};

export default SettingsPanel;