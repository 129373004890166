import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Server, 
  Activity, 
  Users, 
  Clock, 
  Cpu, 
  HardDrive, 
  BarChart, 
  AlertCircle,
  CheckCircle2,
  RefreshCw
} from 'lucide-react';
import axios from 'axios';
import { API_URL } from './api_config';

interface ServerStats {
  status: 'online' | 'offline' | 'degraded';
  uptime: string;
  cpu: number;
  memory: {
    used: number;
    total: number;
  };
  activeUsers: number;
  lastIncident?: string;
  responseTime: number;
}

const ServerStatus: React.FC = () => {
  const [stats, setStats] = useState<ServerStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchServerStats = async () => {
    try {
      // Simulé pour l'exemple - À remplacer par votre vraie API
      const response = await axios.get(API_URL +  '/server/status');
      setStats(response.data);
      setError(null);
    } catch (err) {
      // Pour la démo, on simule des données
      setStats({
        status: 'online',
        uptime: '2 hours',
        cpu: 27,
        memory: {
          used: 2.2,
          total: 8
        },
        activeUsers: 2,
        responseTime: 180
      });
      setError(null);
    }
  };

  useEffect(() => {
    fetchServerStats();
    const interval = setInterval(fetchServerStats, 30000); // Refresh every 30s
    return () => clearInterval(interval);
  }, []);

  const handleManualRefresh = async () => {
    setIsRefreshing(true);
    await fetchServerStats();
    setTimeout(() => setIsRefreshing(false), 500);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'online':
        return 'bg-green-500';
      case 'offline':
        return 'bg-red-500';
      case 'degraded':
        return 'bg-yellow-500';
      default:
        return 'bg-gray-500';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'online':
        return <CheckCircle2 className="text-green-500" size={24} />;
      case 'offline':
        return <AlertCircle className="text-red-500" size={24} />;
      case 'degraded':
        return <AlertCircle className="text-yellow-500" size={24} />;
      default:
        return <AlertCircle className="text-gray-500" size={24} />;
    }
  };

  return (
    <motion.div
      className="p-8 bg-gradient-to-br from-[#1A1C23] to-[#252A34] rounded-2xl shadow-2xl"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <Server className="mr-3 text-[#611BE2]" size={28} />
          <h2 className="text-2xl font-bold text-white">Server Status</h2>
        </div>
        <motion.button
          onClick={handleManualRefresh}
          className="p-2 hover:bg-[#2C3038] rounded-lg transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={isRefreshing}
        >
          <RefreshCw 
            className={`text-[#611BE2] transition-all ${isRefreshing ? 'animate-spin' : ''}`} 
            size={20} 
          />
        </motion.button>
      </div>

      {stats && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Status Card */}
          <motion.div
            className="bg-[#2C3038] p-6 rounded-xl border-2 border-[#3D4149]"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.2 }}
          >
            <div className="flex items-center justify-between mb-4">
              <span className="text-gray-400">Status</span>
              {getStatusIcon(stats.status)}
            </div>
            <div className="flex items-center space-x-2">
              <div 
                className={`w-3 h-3 rounded-full ${getStatusColor(stats.status)} animate-pulse`}
              />
              <span className="text-2xl font-bold text-white capitalize">
                {stats.status}
              </span>
            </div>
          </motion.div>

          {/* Uptime Card */}
          <motion.div
            className="bg-[#2C3038] p-6 rounded-xl border-2 border-[#3D4149]"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.2, delay: 0.1 }}
          >
            <div className="flex items-center justify-between mb-4">
              <span className="text-gray-400">Uptime</span>
              <Clock className="text-[#611BE2]" size={24} />
            </div>
            <span className="text-2xl font-bold text-white">{stats.uptime}</span>
          </motion.div>

          {/* Response Time Card */}
          <motion.div
            className="bg-[#2C3038] p-6 rounded-xl border-2 border-[#3D4149]"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.2, delay: 0.2 }}
          >
            <div className="flex items-center justify-between mb-4">
              <span className="text-gray-400">Response Time</span>
              <Activity className="text-[#611BE2]" size={24} />
            </div>
            <div className="flex items-baseline space-x-2">
              <span className="text-2xl font-bold text-white">{stats.responseTime}</span>
              <span className="text-gray-400">ms</span>
            </div>
          </motion.div>

          {/* CPU Usage Card */}
          <motion.div
            className="bg-[#2C3038] p-6 rounded-xl border-2 border-[#3D4149]"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.2, delay: 0.3 }}
          >
            <div className="flex items-center justify-between mb-4">
              <span className="text-gray-400">CPU Usage</span>
              <Cpu className="text-[#611BE2]" size={24} />
            </div>
            <div className="space-y-2">
              <div className="flex items-baseline space-x-2">
                <span className="text-2xl font-bold text-white">{stats.cpu}%</span>
              </div>
              <div className="w-full bg-[#1A1C23] rounded-full h-2">
                <motion.div
                  className="bg-[#611BE2] h-2 rounded-full"
                  initial={{ width: 0 }}
                  animate={{ width: `${stats.cpu}%` }}
                  transition={{ duration: 0.5 }}
                />
              </div>
            </div>
          </motion.div>

          {/* Memory Usage Card */}
          <motion.div
            className="bg-[#2C3038] p-6 rounded-xl border-2 border-[#3D4149]"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.2, delay: 0.4 }}
          >
            <div className="flex items-center justify-between mb-4">
              <span className="text-gray-400">Memory Usage</span>
              <HardDrive className="text-[#611BE2]" size={24} />
            </div>
            <div className="space-y-2">
              <div className="flex items-baseline space-x-2">
                <span className="text-2xl font-bold text-white">
                  {stats.memory.used}GB
                </span>
                <span className="text-gray-400">
                  / {stats.memory.total}GB
                </span>
              </div>
              <div className="w-full bg-[#1A1C23] rounded-full h-2">
                <motion.div
                  className="bg-[#611BE2] h-2 rounded-full"
                  initial={{ width: 0 }}
                  animate={{ width: `${(stats.memory.used / stats.memory.total) * 100}%` }}
                  transition={{ duration: 0.5 }}
                />
              </div>
            </div>
          </motion.div>

          {/* Active Users Card */}
          <motion.div
            className="bg-[#2C3038] p-6 rounded-xl border-2 border-[#3D4149]"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.2, delay: 0.5 }}
          >
            <div className="flex items-center justify-between mb-4">
              <span className="text-gray-400">Active Users</span>
              <Users className="text-[#611BE2]" size={24} />
            </div>
            <span className="text-2xl font-bold text-white">
              {stats.activeUsers.toLocaleString()}
            </span>
          </motion.div>
        </div>
      )}

      {error && (
        <motion.div
          className="bg-red-500/10 border-2 border-red-500/20 rounded-xl p-4 mt-4"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div className="flex items-center space-x-2 text-red-500">
            <AlertCircle size={20} />
            <span>{error}</span>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default ServerStatus;