import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Settings,
  CheckCircle2,
  AlertCircle,
  Trophy,
  Activity,
  StopCircle,
  Timer,
  Target,
  GitCommitHorizontal,
} from "lucide-react";
import axios from "axios";
import { debounce } from "lodash";
import { API_URL } from "components/api_config";
import toast from "react-hot-toast";

interface Toast {
  id: number;
  message: string;
  type: "success" | "error";
}

interface LastBingoWinner {
  id: number;
  username: string;
  pfp: string;
  createdAt: string;
  updatedAt: string;
}

interface ChatMessage {
  id: string;
  content: string;
  created_at: string;
  sender: {
    username: string;
    identity: {
      color: string;
      badges: string[];
    };
  };
}

interface PusherMessage {
  event: string;
  data: string;
  channel: string;
}

interface BingoState {
  id: number;
  minNumber: number;
  maxNumber: number;
  correctNumber: number;
  status: "pending" | "active" | "completed";
  createdAt: string;
}

interface EditableFields {
  minNumber: number;
  maxNumber: number;
  correctNumber: number;
}

interface KickUserResponse {
  id: number;
  username: string;
  profile_pic: string | null;
}

interface Winner {
  id: number;
  username: string;
  pfp: string;
  createdAt: string;
  updatedAt: string;
}

interface WinnersResponse {
  data: Winner[];
  meta: {
    total: number;
    page: number;
    limit: number;
    totalPages: number;
  };
}

const getUserPfp = async (username: string): Promise<string> => {
  try {
    const response = await axios.get<KickUserResponse>(
      `https://kick.com/api/v2/channels/empiredrop/users/${username}`
    );
    return (
      response.data.profile_pic ||
      "https://kick.com/img/default-profile-pictures/default2.jpeg"
    );
  } catch (error) {
    console.error("Error fetching user pfp:", error);
    return "https://kick.com/img/default-profile-pictures/default2.jpeg";
  }
};

export const BingoSettings: React.FC = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [winnerFound, setWinnerFound] = useState(false);
  const [showWinner, setShowWinner] = useState(false);
  const [lastWinner, setLastWinner] = useState<LastBingoWinner | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [winners, setWinners] = useState<Winner[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const ITEMS_PER_PAGE = 5;
  const [winningMessage, setWinningMessage] = useState<any>(null);
  const [showEndMessage, setShowEndMessage] = useState(false);
  const chatRef = useRef<HTMLDivElement>(null);
  const [isDrawInitiating, setIsDrawInitiating] = useState(false);
  const [isStoppingDraw, setIsStoppingDraw] = useState(false);
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [bingoState, setBingoState] = useState<BingoState | null>(null);
  const [editableFields, setEditableFields] = useState<EditableFields>({
    minNumber: 1,
    maxNumber: 500,
    correctNumber: 1,
  });
  const [remainingTime, setRemainingTime] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  useEffect(() => {
    const fetchLastWinner = async () => {
      try {
        const response = await axios.get(`${API_URL}/bingo/winner`);
        setLastWinner(response.data.data);
      } catch (error) {
        console.error("Failed to fetch last winner:", error);
      }
    };

    fetchLastWinner(); // Initial fetch
    const interval = setInterval(fetchLastWinner, 1000); // Fetch every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  const addToast = (message: string, type: "success" | "error") => {
    const newToast = { id: Date.now(), message, type };
    setToasts((prev) => [...prev, newToast]);
    setTimeout(
      () => setToasts((prev) => prev.filter((t) => t.id !== newToast.id)),
      3000
    );
  };

  const PaginationControls = () => (
    <div className="flex items-center justify-between mt-4 border-t border-gray-800 pt-4">
      <button
        onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
        disabled={currentPage === 1}
        className="px-4 py-2 text-sm bg-gray-800/50 rounded-lg text-gray-400 hover:bg-gray-800 
                   disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
      >
        Previous
      </button>
      <div className="flex items-center gap-2">
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => setCurrentPage(i + 1)}
            className={`w-8 h-8 rounded-lg text-sm transition-all duration-200
              ${
                currentPage === i + 1
                  ? "bg-violet-500 text-white"
                  : "bg-gray-800/50 text-gray-400 hover:bg-gray-800"
              }`}
          >
            {i + 1}
          </button>
        ))}
      </div>
      <button
        onClick={() => setCurrentPage((prev) => Math.min(totalPages, prev + 1))}
        disabled={currentPage === totalPages}
        className="px-4 py-2 text-sm bg-gray-800/50 rounded-lg text-gray-400 hover:bg-gray-800 
                   disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
      >
        Next
      </button>
    </div>
  );

  const handleRandomNumber = async () => {
    if (!bingoState) return;

    const min = editableFields.minNumber;
    const max = editableFields.maxNumber;
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;

    try {
      setIsUpdating(true);
      await axios.put(`${API_URL}/bingo/update`, {
        ...bingoState,
        correctNumber: randomNum,
      });

      setEditableFields((prev) => ({
        ...prev,
        correctNumber: randomNum,
      }));

      addToast("Random number generated! 🎲", "success");
    } catch (error) {
      addToast("Failed to generate random number", "error");
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    const fetchWinners = async () => {
      try {
        const response = await axios.get<WinnersResponse>(
          `${API_URL}/bingo/winners?page=${currentPage}&limit=${ITEMS_PER_PAGE}`
        );
        setWinners(response.data.data);
        setTotalPages(response.data.meta.totalPages);
      } catch (error) {
        console.error("Failed to fetch winners:", error);
      }
    };

    fetchWinners();
    const interval = setInterval(fetchWinners, 1000);
    return () => clearInterval(interval);
  }, [currentPage]);

  useEffect(() => {
    const ws = new WebSocket(
      "wss://ws-us2.pusher.com/app/32cbd69e4b950bf97679?protocol=7&client=js&version=8.4.0-rc2&flash=false"
    );

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          event: "pusher:subscribe",
          data: { auth: "", channel: "chatrooms.29877896.v2" },
        })
      );
    };

    ws.onmessage = async (event) => {
      const data: PusherMessage = JSON.parse(event.data);
      console.log("Message received:", data);

      if (data.event === "App\\Events\\ChatMessageEvent") {
        const messageData = JSON.parse(data.data);
        console.log(
          "Chat message:",
          messageData,
          "Bingo status:",
          bingoState?.status
        );

        // Only add message if bingo is active and no winner
        if (bingoState?.status === "active" && !winnerFound) {
          setMessages((prev) => [...prev, messageData]);

          // Check if message is winning number
          if (messageData.content === bingoState.correctNumber.toString()) {
            setWinnerFound(true);
            setWinningMessage(messageData);

            try {
              const pfp = await getUserPfp(messageData.sender.username);

              // Update winner in database
              await axios.put(`${API_URL}/bingo/winner`, {
                username: messageData.sender.username,
                pfp,
              });

              // Update game status
              await axios.put(`${API_URL}/bingo/update`, {
                ...bingoState,
                status: "completed",
              });
              setTimeout(async () => {
                try {
                  await axios.put(`${API_URL}/bingo/update`, {
                    ...bingoState,
                    status: "pending",
                  });
                  setWinnerFound(false);
                  setMessages([]);
                } catch (error) {
                  console.error("Error resetting bingo status:", error);
                }
              }, 7000);

              // Show winner animation
              setTimeout(() => {
                setTimeout(() => {
                  setShowWinner(false);
                  setShowEndMessage(true);
                }, 5000);
              }, 5000);
            } catch (error) {
              console.error("Error updating winner:", error);
            }
          }
        }
      }
    };

    return () => ws.close();
  }, [bingoState?.status, bingoState?.correctNumber, winnerFound]);

  // Debounced update function
  const debouncedUpdate = useCallback(
    debounce(async (updates: EditableFields) => {
      setIsUpdating(true);
      try {
        await axios.put(`${API_URL}/bingo/update`, updates);
        addToast("Settings updated ✨", "success");
      } catch (err) {
        addToast("Failed to update settings", "error");
      } finally {
        setIsUpdating(false);
        setIsEditing(false); // Reset editing state after update
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (bingoState) {
      setEditableFields({
        minNumber: bingoState.minNumber,
        maxNumber: bingoState.maxNumber,
        correctNumber: bingoState.correctNumber,
      });
    }
  }, [bingoState]);

  // Handle field updates
  const handleFieldUpdate = (field: string, value: number) => {
    setIsEditing(true);
    setEditableFields((prev) => ({
      ...prev,
      [field]: value,
    }));
    debouncedUpdate({ ...editableFields, [field]: value });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (bingoState?.status === "active" && remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prev) => Math.max(0, prev - 1));
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [bingoState?.status, remainingTime]);

  useEffect(() => {
    const fetchGameState = async () => {
      if (isEditing) return; // Skip fetch if editing
      try {
        const response = await axios.get(`${API_URL}/bingo/current`);
        setBingoState(response.data.data);
      } catch (error) {
        console.error("Failed to fetch game state:", error);
      }
    };

    const interval = setInterval(fetchGameState, 1000);
    return () => clearInterval(interval);
  }, [isEditing]);

  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  const handleInitiateDraw = async () => {
    setIsDrawInitiating(true);
    try {
      // Update bingo state to active
      await axios.put(`${API_URL}/bingo/update`, {
        ...bingoState,
        status: "active",
      });

      // Reset states
      setWinnerFound(false);
      setShowWinner(false);
      setShowEndMessage(false);
      setMessages([]);
      setRemainingTime(60);

      // Fetch updated state
      const response = await axios.get(`${API_URL}/bingo/current`);
      setBingoState(response.data.data);

      toast.success("Bingo started successfully");
    } catch (error) {
      toast.error("Failed to start bingo");
    } finally {
      setIsDrawInitiating(false);
    }
  };

  const stopDraw = async () => {
    setIsStoppingDraw(true);
    try {
      await axios.put(`${API_URL}/bingo/update`, {
        ...bingoState,
        status: "pending",
      });

      // Reset states
      setWinnerFound(false);
      setShowWinner(false);
      setShowEndMessage(false);
      setMessages([]);
      setRemainingTime(60);

      // Fetch updated state
      const response = await axios.get(`${API_URL}/bingo/current`);
      setBingoState(response.data.data);

      toast.success("Bingo started successfully");
    } catch (err) {
      addToast("Failed to stop bingo", "error");
    } finally {
      setIsStoppingDraw(false);
    }
  };

  const getInputStyle = (isUpdating: boolean) => `
    w-full bg-black/20 text-white border ${
      isUpdating ? "border-violet-500" : "border-violet-500/20"
    } 
    rounded-xl py-3 px-4 focus:outline-none focus:border-violet-500 transition-all
    ${isUpdating ? "animate-pulse" : ""}
  `;

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-8">
        <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-violet-900/20 via-gray-900 to-black p-6">
          <div className="max-w-6xl mx-auto">
            <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="p-3 bg-amber-500/10 rounded-xl ring-1 ring-amber-500/20">
                    <Trophy className="w-8 h-8 text-amber-400" />
                  </div>
                  <div>
                    <h2 className="text-xl font-semibold text-white">
                      Last Winner
                    </h2>
                    <p className="text-sm text-gray-400">
                      {lastWinner
                        ? new Date(lastWinner.createdAt).toLocaleString()
                        : "No winner yet"}
                    </p>
                  </div>
                </div>

                {lastWinner && (
                  <div className="flex items-center gap-3">
                    <div className="text-right">
                      <p className="text-lg font-semibold text-white">
                        {lastWinner.username}
                      </p>
                    </div>
                    <img
                      src={lastWinner.pfp}
                      alt={lastWinner.username}
                      className="w-12 h-12 rounded-lg border border-amber-500/20"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6 mb-6">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <Activity className="text-violet-400 mr-2" size={24} />
                  <h3 className="text-xl font-semibold text-white">
                    Bingo Status
                  </h3>
                </div>
              </div>
              <div className="bg-black/20 p-4 rounded-xl border border-violet-500/20">
                <span
                  className={`text-lg font-medium ${
                    bingoState?.status === "pending"
                      ? "text-gray-400"
                      : "text-green-400"
                  }`}
                >
                  {!bingoState
                    ? "Loading..."
                    : bingoState.status === "pending"
                    ? "No active bingo"
                    : bingoState.status === "active"
                    ? "Bingo in progress"
                    : "Bingo completed"}
                </span>
              </div>
            </div>

            <motion.div
              className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="p-6">
                <div className="flex items-center mb-6">
                  <div className="p-3 bg-violet-500/10 rounded-xl ring-1 ring-violet-500/20">
                    <Settings className="w-8 h-8 text-violet-400" />
                  </div>
                  <div className="ml-4">
                    <h2 className="text-3xl font-bold text-white">
                      Bingo Configuration
                    </h2>
                    <p className="text-gray-400 text-sm">
                      Settings auto-save as you type
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                  <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
                    <div className="flex items-center mb-4">
                      <GitCommitHorizontal
                        className="text-violet-400 mr-2"
                        size={20}
                      />
                      <h3 className="text-lg font-semibold text-white">
                        Min Number
                      </h3>
                    </div>
                    <input
                      type="number"
                      value={editableFields.minNumber}
                      onChange={(e) =>
                        handleFieldUpdate("minNumber", parseInt(e.target.value))
                      }
                      className={getInputStyle(isUpdating)}
                      min="1"
                    />
                  </div>

                  <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
                    <div className="flex items-center mb-4">
                      <GitCommitHorizontal
                        className="text-violet-400 mr-2"
                        size={20}
                      />
                      <h3 className="text-lg font-semibold text-white">
                        Max Number
                      </h3>
                    </div>
                    <input
                      type="number"
                      value={editableFields.maxNumber}
                      onChange={(e) =>
                        handleFieldUpdate("maxNumber", parseInt(e.target.value))
                      }
                      className={getInputStyle(isUpdating)}
                      min="1"
                    />
                  </div>

                  <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
                    <div className="flex items-center mb-4">
                      <Target className="text-violet-400 mr-2" size={20} />
                      <h3 className="text-lg font-semibold text-white">
                        Correct Number
                      </h3>
                    </div>
                    <input
                      type="number"
                      value={editableFields.correctNumber}
                      onChange={(e) =>
                        handleFieldUpdate(
                          "correctNumber",
                          parseInt(e.target.value)
                        )
                      }
                      className={getInputStyle(isUpdating)}
                      min="1"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <motion.button
                    onClick={handleInitiateDraw}
                    className="bg-violet-500 hover:bg-violet-600 text-white py-4 px-6 rounded-xl font-semibold
                         transition-all duration-200 flex items-center justify-center space-x-2
                         disabled:opacity-50 disabled:cursor-not-allowed"
                    whileHover={{ scale: isDrawInitiating ? 1 : 1.02 }}
                    whileTap={{ scale: isDrawInitiating ? 1 : 0.98 }}
                    disabled={
                      isDrawInitiating || bingoState?.status === "active"
                    }
                  >
                    <Trophy size={20} />
                    <span>
                      {isDrawInitiating ? "Starting..." : "Start New Bingo"}
                    </span>
                  </motion.button>

                  <motion.button
                    onClick={stopDraw}
                    className="bg-red-500/20 hover:bg-red-500/30 text-red-400 py-4 px-6 rounded-xl font-semibold
                         border border-red-500/20 transition-all duration-200 flex items-center justify-center space-x-2
                         disabled:opacity-50 disabled:cursor-not-allowed"
                    whileHover={{ scale: isStoppingDraw ? 1 : 1.02 }}
                    whileTap={{ scale: isStoppingDraw ? 1 : 0.98 }}
                  >
                    <StopCircle size={20} />
                    <span>{isStoppingDraw ? "Stopping..." : "Stop Bingo"}</span>
                  </motion.button>
                  <motion.button
                    onClick={handleRandomNumber}
                    className="w-full bg-violet-500/20 hover:bg-violet-500/30 text-violet-400 py-3 px-6 rounded-xl font-semibold
             border border-violet-500/20 transition-all duration-200 flex items-center justify-center gap-2"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    disabled={isUpdating}
                  >
                    <div className="w-5 h-5 relative">
                      {isUpdating ? (
                        <div className="absolute inset-0 animate-spin border-2 border-violet-500/20 border-t-violet-400 rounded-full" />
                      ) : (
                        <div className="text-lg">🎲</div>
                      )}
                    </div>
                    <span>
                      {isUpdating ? "Generating..." : "Generate Random Number"}
                    </span>
                  </motion.button>
                </div>
              </div>
            </motion.div>
          </div>
          <br></br>
          <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6 mb-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <Trophy className="text-amber-400 mr-2" size={24} />
                <h3 className="text-xl font-semibold text-white">
                  Winners History
                </h3>
              </div>
              <span className="text-sm text-gray-400">
                Page {currentPage} of {totalPages}
              </span>
            </div>

            <div className="space-y-4">
              {winners.map((winner) => (
                <motion.div
                  key={winner.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="flex items-center justify-between p-4 bg-black/20 rounded-xl border border-gray-800/50"
                >
                  <div className="flex items-center gap-3">
                    <img
                      src={winner.pfp}
                      alt={winner.username}
                      className="w-10 h-10 rounded-lg border border-amber-500/20"
                    />
                    <div>
                      <p className="text-white font-medium">
                        {winner.username}
                      </p>
                      <p className="text-sm text-gray-400">
                        {new Date(winner.createdAt).toLocaleString()}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>

            {totalPages > 1 && <PaginationControls />}
          </div>
          <div className="fixed bottom-4 right-4 space-y-2 z-50">
            <AnimatePresence>
              {toasts.map((toast) => (
                <motion.div
                  key={toast.id}
                  initial={{ opacity: 0, y: 20, scale: 0.95 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, y: -20, scale: 0.95 }}
                  className={`backdrop-blur-xl ${
                    toast.type === "success"
                      ? "bg-green-500/80 border-green-400"
                      : "bg-red-500/80 border-red-400"
                  } text-white p-4 rounded-xl border flex items-center space-x-2 shadow-lg`}
                >
                  {toast.type === "success" ? (
                    <CheckCircle2 size={20} />
                  ) : (
                    <AlertCircle size={20} />
                  )}
                  <span className="font-medium">{toast.message}</span>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
      </div>

      <div className="col-span-4">
        <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
          <h2 className="text-xl font-semibold text-white mb-4">Live Chat</h2>

          <AnimatePresence>
            {showWinner ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                className="absolute inset-0 flex items-center justify-center bg-black/80"
              >
                <div className="text-center">
                  <Trophy className="w-20 h-20 text-amber-400 mx-auto mb-4" />
                  <h3 className="text-2xl font-bold text-amber-400">Winner!</h3>
                  <p className="text-xl text-white mt-2">
                    {winningMessage?.sender.username}
                  </p>
                </div>
              </motion.div>
            ) : showEndMessage ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex items-center justify-center h-[600px] text-gray-400"
              >
                Start a bingo to see live chat
              </motion.div>
            ) : (
              <div
                ref={chatRef}
                className="h-[600px] overflow-y-auto space-y-2 custom-scrollbar"
              >
                {messages.map((msg) => {
                  const isWinner =
                    msg.content === bingoState?.correctNumber.toString();
                  return (
                    <div
                      key={msg.id}
                      className={`flex items-start gap-2 p-2 rounded-lg transition-all duration-300 ${
                        isWinner ? "bg-amber-500/20 animate-pulse" : ""
                      }`}
                    >
                      {isWinner && (
                        <Trophy className="w-5 h-5 text-amber-400" />
                      )}
                      <span
                        className={`font-medium ${
                          isWinner ? "text-amber-400" : ""
                        }`}
                        style={{
                          color: !isWinner
                            ? msg.sender.identity.color
                            : undefined,
                        }}
                      >
                        {msg.sender.username}:
                      </span>
                      <span
                        className={`${
                          isWinner ? "text-amber-400 font-bold" : "text-white"
                        }`}
                      >
                        {msg.content}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default BingoSettings;
