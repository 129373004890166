import { API_URL } from "components/api_config";
import { motion } from "framer-motion";
import { Sliders, X } from "lucide-react";
import { useState } from "react";

interface Tag {
    name: string;
    slug: string;
    color: string;
  }
  
  interface Category {
    name: string;
    slug: string;
    type: string;
  }
  

  interface FilterState {
    priceMin: number;
    priceMax: number;
    categories: string[];
  }

  interface SlugPrice {
    price: number;
    slug: string;
  }
  
  interface BoxData {
    name: string;
    slug: string;
    image: string;
    price: number;
    tags: Tag[];
    enabled: boolean;
    categories: Category[];
  }

const FilterMenu: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    filters: FilterState;
    onFiltersChange: (filters: FilterState) => void;
    availableCategories: { name: string; slug: string; type: string; }[];
    boxes: BoxData[];
  }> = ({ isOpen, onClose, filters, onFiltersChange, availableCategories, boxes }) => {
    const [localFilters, setLocalFilters] = useState(filters);
    const [categoryStatus, setCategoryStatus] = useState<{ [key: string]: boolean }>({});
  
  
    const handlePriceChange = (type: 'min' | 'max', value: string) => {
      const numValue = value === '' ? (type === 'min' ? 0 : 500000) : parseInt(value);
      setLocalFilters(prev => ({
        ...prev,
        [type === 'min' ? 'priceMin' : 'priceMax']: numValue
      }));
    };
  
    const handleCategoryToggle = (slug: string) => {
      setLocalFilters(prev => ({
        ...prev,
        categories: prev.categories.includes(slug)
          ? prev.categories.filter(cat => cat !== slug)
          : [...prev.categories, slug]
      }));
    };
  
    
  
    const handleApply = async () => {
      try {
       
        const matchingBoxes = boxes.filter(box => {
          const matchesPrice = box.price >= localFilters.priceMin && box.price <= localFilters.priceMax;
          const matchesCategory = localFilters.categories.length === 0 ||
            box.categories.some(cat => localFilters.categories.includes(cat.slug));
          return matchesPrice && matchesCategory;
        });
    
       
    
        onFiltersChange(localFilters);
        try {
          const response = await fetch(API_URL +  '/filters/price-range', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              minPrice: localFilters.priceMin / 100, 
              maxPrice: localFilters.priceMax / 100,
            }),
          });
      
          if (!response.ok) throw new Error('Failed to update price range');
          console.log('Price range updated successfully');
        } catch (error) {
          console.error('Error updating price range:', error);
        }
        
        onClose();
      } catch (error) {
        console.error('Error applying filters:', error);
      }
    };
  
    const handleReset = () => {
      const resetFilters = {
        priceMin: 0,
        priceMax: 500000,
        categories: []
      };
      setLocalFilters(resetFilters);
      onFiltersChange(resetFilters);
    };
  
    if (!isOpen) return null;
  
    return (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="absolute right-0 top-full mt-2 w-80 bg-[#1A1C23] border border-[#3D4149] rounded-xl shadow-xl z-50"
      >
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-white font-semibold flex items-center gap-2">
              <Sliders size={18} className="text-[#611BE2]" />
              Filters
            </h3>
            <button
              onClick={onClose}
              className="p-1 hover:bg-[#2C3038] rounded-lg transition-colors"
            >
              <X size={18} className="text-gray-400" />
            </button>
          </div>
  
          <div className="space-y-4">
            <div>
              <h4 className="text-gray-400 text-sm mb-2">Price Range (€)</h4>
              <div className="flex gap-2">
                <input
                  type="number"
                  value={localFilters.priceMin / 100}
                  onChange={(e) => handlePriceChange('min', (parseFloat(e.target.value) * 100).toString())}
                  min="0"
                  max={localFilters.priceMax / 100}
                  className="w-1/2 bg-[#2C3038] text-white px-3 py-2 rounded-lg border border-[#3D4149] focus:border-[#611BE2] focus:outline-none"
                />
               
                <input
                  type="number"
                  value={localFilters.priceMax / 100}
                  onChange={(e) => handlePriceChange('max', (parseFloat(e.target.value) * 100).toString())}
                  min={localFilters.priceMin / 100}
                  className="w-1/2 bg-[#2C3038] text-white px-3 py-2 rounded-lg border border-[#3D4149] focus:border-[#611BE2] focus:outline-none"
                  placeholder="Max"
                />
              </div>
            </div>
  
            <div>
              <h4 className="text-gray-400 text-sm mb-2">Categories</h4>
              <div className="space-y-2 max-h-40 overflow-y-auto custom-scrollbar">
                {availableCategories.map((category) => (
                  <label
                    key={category.slug}
                    className="flex items-center gap-2 text-white cursor-pointer p-2 hover:bg-[#2C3038] rounded-lg"
                  >
                    <input
                      type="checkbox"
                      checked={localFilters.categories.includes(category.slug)}
                      onChange={() => handleCategoryToggle(category.slug)}
                      className="rounded border-[#3D4149] text-[#611BE2] focus:ring-[#611BE2]"
                    />
                    {category.name}
                  </label>
                ))}
              </div>
            </div>
          </div>
  
          <div className="flex gap-2 mt-4">
            <motion.button
              onClick={handleReset}
              className="flex-1 px-4 py-2 bg-[#2C3038] text-white rounded-lg hover:bg-[#3D4149] transition-colors"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Reset
            </motion.button>
            <motion.button
              onClick={handleApply}
              className="flex-1 px-4 py-2 bg-[#611BE2] text-white rounded-lg hover:bg-[#4F15B8] transition-colors"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Apply
            </motion.button>
          </div>
        </div>
      </motion.div>
    );
  };

export default FilterMenu;