import { AnimatePresence, motion } from "framer-motion";
import { Trash, XCircle } from "lucide-react";

interface DeleteConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    itemType?: string;
  }
  
  const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    itemType = "item",
  }) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-gray-900 rounded-xl p-6 w-full max-w-md shadow-2xl border border-gray-700"
            >
              <div className="flex flex-col items-center space-y-4 text-center">
                <div className="bg-red-500/10 p-4 rounded-full">
                  <Trash className="text-red-400" size={40} />
                </div>
  
                <div>
                  <h2 className="text-xl font-semibold text-white mb-2">
                    Delete {itemType}
                  </h2>
                  <p className="text-gray-400 text-sm">
                    Are you sure you want to delete this {itemType}? This action
                    cannot be undone.
                  </p>
                </div>
  
                <div className="flex space-x-4 w-full pt-4">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onClose}
                    className="flex-1 px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center justify-center gap-2"
                  >
                    <XCircle size={20} /> Cancel
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onConfirm}
                    className="flex-1 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500 transition-colors flex items-center justify-center gap-2"
                  >
                    <Trash size={20} /> Delete
                  </motion.button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

export default DeleteConfirmationModal;