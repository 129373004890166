import React, { useEffect, useMemo, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Users,
  Settings,
  Bell,
  Search,
  Menu,
  X,
  ChevronRight,
  UserPlus,
  Ban,
  AlertTriangle,
  Edit,
  Trash,
  MoreVertical,
  Filter,
  Save,
  XCircle,
  KeyRound,
  Check,
} from "lucide-react";

import Sidebar from "components/Layouts/Sidebar";
import Header from "components/Layouts/Header";
import toast from "react-hot-toast";
import axios from "axios";
import { API_URL } from "components/api_config";
import DeleteConfirmationModal from "./Modals/DeleteConfirmModal";
import ConfirmModal from "./Modals/Confirmation";

type Role = 'user' | 'streamer' | 'moderator' | 'admin' | 'developer';


interface User {
  pfp: any;
  id: number;
  username: string;
  email: string;
  confirmed: boolean;
  isAdmin: boolean | null;
  role: Role;
  createdAt: string;
}

interface EditForm {
  username: string;
  email: string;
  isAdmin: boolean;
  confirmed: boolean;
  role: Role;
}

const UserAll: React.FC<{
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
}> = ({ isSidebarOpen, setIsSidebarOpen, isMobile }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState<string>("all");
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [resetPasswordUser, setResetPasswordUser] = useState<{
    id: string;
    password: string;
  } | null>(null);


  const [confirmationModal, setConfirmationModal] = useState<{
    isOpen: boolean;
    userId: string | null;
  }>({
    isOpen: false,
    userId: null,
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<number | null>(null);

  const openDeleteModal = (userId: number) => {
    setUserToDelete(userId);
    setIsDeleteModalOpen(true);
  };

  const generatePassword = () => {
    const chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  };

  const handleResetPassword = async () => {
    if (!confirmationModal.userId) return;

    const loadingToast = toast.loading("Resetting password...");
    try {
      const response = await axios.post(
        `${API_URL}/users/${confirmationModal.userId}/reset-password`
      );

      const { newPassword } = response.data;

      setResetPasswordUser({
        id: confirmationModal.userId,
        password: newPassword,
      });
      setShowPasswordModal(true); // Ouvrir le modal
      closeConfirmationModal();
      toast.success("Password reset successfully", { id: loadingToast });
    } catch (error) {
      console.error(error);
      toast.error("Failed to reset password", { id: loadingToast });
    }
  };

  const confirmDeleteUser = async () => {
    if (userToDelete) {
      try {
        const response = await fetch(
          `${API_URL}/delete-profil/${userToDelete}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          setUsers(users.filter((user) => user.id !== userToDelete));
        } else {
          const errorData = await response.json();
          alert(errorData.message || "Failed to delete user");
        }
      } catch (error) {
        console.error("Error deleting user:", error);
        alert("Failed to delete user");
      }

      setIsDeleteModalOpen(false);
      setUserToDelete(null);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        API_URL + "/all-profile"
      );
      const data = await response.json();
      setUsers(data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch users", error);
      setLoading(false);
    }
  };

  const openConfirmationModal = (userId: string) => {
    setConfirmationModal({ isOpen: true, userId });
  };

  const closeConfirmationModal = () => {
    setConfirmationModal({ isOpen: false, userId: null });
  };

  const [editForm, setEditForm] = useState<EditForm>({
    username: '',
    email: '',
    isAdmin: false,
    confirmed: false,
    role: 'user'
  });
  

  const handleEditUser = (user: User) => {
    setEditingUser(user);
    setEditForm({
      username: user.username,
      email: user.email,
      isAdmin: user.isAdmin || false,
      confirmed: user.confirmed,
      role: user.role || 'user'
    });
  };
  const updateUser = async () => {
    if (!editingUser) return;

    try {
      const response = await fetch(
        `${API_URL}/update-profil/${editingUser.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: editForm.username,
            email: editForm.email,
            confirmed: editForm.confirmed,
            isAdmin: editForm.isAdmin,
            role: editForm.role
          }),
        }
      );

      if (response.ok) {
        // Update local state
        const updatedUsers = users.map((user) =>
          user.id === editingUser.id ? { ...user, ...editForm } : user
        );
        setUsers(updatedUsers);
        setEditingUser(null);
      } else {
        const errorData = await response.json();
        alert(errorData.message || "Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Failed to update user");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    document.title = "EmpireDrop - Admin";
  }, []);

  const getStatusColor = (confirmed: boolean) => {
    return confirmed
      ? "bg-green-400/10 text-green-400"
      : "bg-yellow-400/10 text-yellow-400";
  };

  const getRoleBadgeColor = (role: string) => {
    switch (role) {
      case 'developer':
        return "bg-red-400/10 text-red-400";
      case 'admin':
        return "bg-purple-400/10 text-purple-400";
      case 'moderator':
        return "bg-blue-400/10 text-blue-400";
      case 'streamer':
        return "bg-amber-400/10 text-amber-400";
      default:
        return "bg-gray-400/10 text-gray-400";
    }
  };

  const getRoleColor = (user: { role?: string; isAdmin: boolean | null }) => {
    if (user.role) {
      return getRoleBadgeColor(user.role);
    }
    return user.isAdmin
      ? "bg-purple-400/10 text-purple-400"
      : "bg-gray-400/10 text-gray-400";
  };

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      const matchesFilter =
        selectedFilter === "all" ||
        (selectedFilter === "active" && user.confirmed) ||
        (selectedFilter === "pending" && !user.confirmed);

      const matchesSearch =
        user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase());

      return matchesFilter && matchesSearch;
    });
  }, [users, selectedFilter, searchQuery]);

  return (
    <div className="flex h-screen bg-gray-100">
      <AnimatePresence>
        {(isSidebarOpen || !isMobile) && (
          <motion.div
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            exit={{ x: -300 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="z-20"
          >
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          </motion.div>
        )}
      </AnimatePresence>

      {confirmationModal.isOpen && (
        <ConfirmModal
          title="Confirm Password Reset"
          description="Are you sure you want to reset this user's password? This action cannot be undone."
          onConfirm={handleResetPassword}
          onCancel={closeConfirmationModal}
        />
      )}

      <div className="flex-1 flex flex-col overflow-hidden">
        <Header
          toggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
          isMobile={isMobile}
        />
        <div className="bg-gradient-to-b from-[#0E1015] to-[#1A1C23] p-6 min-h-screen">
          <div className="space-y-6">
            {/* Stats Overview */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <motion.div
                whileHover={{ y: -2 }}
                className="bg-gradient-to-br from-indigo-500/10 to-indigo-500/5 p-4 rounded-xl border border-indigo-500/20"
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-indigo-500/10 rounded-lg">
                    <Users className="h-5 w-5 text-indigo-400" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Total Users</p>
                    <p className="text-xl font-semibold text-white">
                      {users.length}
                    </p>
                  </div>
                </div>
              </motion.div>

              <motion.div
                whileHover={{ y: -2 }}
                className="bg-gradient-to-br from-green-500/10 to-green-500/5 p-4 rounded-xl border border-green-500/20"
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-green-500/10 rounded-lg">
                    <UserPlus className="h-5 w-5 text-green-400" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Confirmed Users</p>
                    <p className="text-xl font-semibold text-white">
                      {users.filter((user) => user.confirmed).length}
                    </p>
                  </div>
                </div>
              </motion.div>

              <motion.div
                whileHover={{ y: -2 }}
                className="bg-gradient-to-br from-yellow-500/10 to-yellow-500/5 p-4 rounded-xl border border-yellow-500/20"
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-yellow-500/10 rounded-lg">
                    <AlertTriangle className="h-5 w-5 text-yellow-400" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Pending Users</p>
                    <p className="text-xl font-semibold text-white">
                      {users.filter((user) => !user.confirmed).length}
                    </p>
                  </div>
                </div>
              </motion.div>

              <motion.div
                whileHover={{ y: -2 }}
                className="bg-gradient-to-br from-purple-500/10 to-purple-500/5 p-4 rounded-xl border border-purple-500/20"
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-purple-500/10 rounded-lg">
                    <Ban className="h-5 w-5 text-purple-400" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Admin Users</p>
                    <p className="text-xl font-semibold text-white">
                      {users.filter((user) => user.isAdmin).length}
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Search and Filters */}
            <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
              <div className="relative w-full md:w-96">
                <Search
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  size={20}
                />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search users..."
                  className="w-full pl-10 pr-4 py-2 bg-gray-800/50 rounded-lg text-gray-300 
             placeholder-gray-500 focus:outline-none focus:ring-2 
             focus:ring-indigo-500/50 focus:bg-gray-800/80 transition-all"
                />
              </div>

              <div className="flex gap-2">
                {["all", "active", "pending"].map((filter) => (
                  <button
                    key={filter}
                    onClick={() => setSelectedFilter(filter)}
                    className={`px-4 py-2 rounded-lg capitalize transition-all
                  ${
                    selectedFilter === filter
                      ? "bg-indigo-500/20 text-indigo-400 ring-1 ring-indigo-500/50"
                      : "bg-gray-800/50 text-gray-400 hover:bg-gray-800"
                  }`}
                  >
                    {filter}
                  </button>
                ))}
              </div>
            </div>

            {/* Users Table */}
            <div className="bg-gray-800/50 rounded-xl overflow-hidden border border-gray-700/50">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-gray-700/50">
                    <th className="text-left p-4 text-gray-400 font-medium">
                      User
                    </th>
                    <th className="text-left p-4 text-gray-400 font-medium">
                      Status
                    </th>
                    <th className="text-left p-4 text-gray-400 font-medium">
                      Role
                    </th>
                    <th className="text-left p-4 text-gray-400 font-medium">
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user) => (
                    <motion.tr
                      key={user.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      whileHover={{ backgroundColor: "rgba(255,255,255,0.02)" }}
                      className="border-b border-gray-700/50 last:border-0"
                    >
                      <td className="p-4">
                        <div className="flex items-center gap-3">
                          <div className="w-10 h-10 rounded-full bg-gray-700 flex items-center justify-center overflow-hidden">
                            {user.pfp ? (
                              <img
                                src={`https://empire-back.selaris.app${user.pfp}`}
                                alt={user.username}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <span className="text-gray-300 font-medium">
                                {user.username.slice(0, 2).toUpperCase()}
                              </span>
                            )}
                          </div>
                          <div>
                            <p className="text-gray-200 font-medium">
                              {user.username}
                            </p>
                            <p className="text-gray-400 text-sm">
                              {user.email}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="p-4">
                        <span
                          className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(
                            user.confirmed
                          )}`}
                        >
                          {user.confirmed ? "Active" : "Pending"}
                        </span>
                      </td>
                      <td className="p-4">
                      <span className={`px-2 py-1 rounded-lg text-xs font-medium ${getRoleColor(user)}`}>
                        {user.role || (user.isAdmin ? 'Admin' : 'User')}
                      </span>
                      </td>
                      <td className="p-4 text-gray-400">
                        {new Date(user.createdAt).toLocaleDateString()}
                      </td>
                      <td className="p-4">
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() =>
                              openConfirmationModal(user.id.toString())
                            }
                            className="p-2 rounded hover:bg-gray-700 text-yellow-500 hover:text-yellow-400 transition-colors"
                            title="Reset Password"
                          >
                            <KeyRound size={20} />
                          </button>
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleEditUser(user)}
                            className="text-indigo-400 hover:text-indigo-300 transition-colors"
                          >
                            <Edit size={20} />
                          </motion.button>
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => openDeleteModal(user.id)}
                            className="text-red-400 hover:text-red-300 transition-colors"
                          >
                            <Trash size={20} />
                          </motion.button>
                        </div>
                      </td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {resetPasswordUser && showPasswordModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
            onClick={() => setShowPasswordModal(false)}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-gray-900 rounded-xl p-6 w-full max-w-md shadow-2xl border border-gray-700"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-white">
                  Password Reset
                </h2>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setShowPasswordModal(false)}
                  className="text-gray-400 hover:text-white focus:outline-none"
                >
                  <X size={24} />
                </motion.button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    New password
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={resetPasswordUser.password}
                      readOnly
                      className="w-full bg-gray-800 border border-gray-700 rounded-lg px-3 py-2 
                text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-6">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setShowPasswordModal(false)}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg 
            hover:bg-indigo-500 transition-colors flex items-center gap-2"
                >
                  <Check size={20} /> Done
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}

        {editingUser && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-gray-900 rounded-xl p-6 w-full max-w-md shadow-2xl border border-gray-700"
            >
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-white">
                  Edit User Profile
                </h2>
                <motion.button
                  whileHover={{ rotate: 90 }}
                  onClick={() => setEditingUser(null)}
                  className="text-gray-400 hover:text-white"
                >
                  <X size={24} />
                </motion.button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Username
                  </label>
                  <input
                    type="text"
                    value={editForm.username}
                    onChange={(e) =>
                      setEditForm({ ...editForm, username: e.target.value })
                    }
                    className="w-full bg-gray-800 border border-gray-700 rounded-lg px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    value={editForm.email}
                    onChange={(e) =>
                      setEditForm({ ...editForm, email: e.target.value })
                    }
                    className="w-full bg-gray-800 border border-gray-700 rounded-lg px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div className="flex items-center space-x-4">
                <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Role
                </label>
                <select
                  value={editForm.role}
                  onChange={(e) => setEditForm({ ...editForm, role: e.target.value as Role })}
                  className="w-full bg-gray-800 border border-gray-700 rounded-lg px-3 py-2 
                    text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <option value="user">User</option>
                  <option value="streamer">Streamer</option>
                  <option value="moderator">Moderator</option>
                  <option value="admin">Admin</option>
                  <option value="developer">Developer</option>
                </select>
              </div>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={editForm.confirmed}
                      onChange={(e) =>
                        setEditForm({
                          ...editForm,
                          confirmed: e.target.checked,
                        })
                      }
                      className="form-checkbox h-4 w-4 text-indigo-600 bg-gray-800 border-gray-700 rounded"
                    />
                    <span className="text-gray-300">Confirmed</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={editForm.isAdmin}
                      onChange={(e) =>
                        setEditForm({ ...editForm, isAdmin: e.target.checked })
                      }
                      className="form-checkbox h-4 w-4 text-indigo-600 bg-gray-800 border-gray-700 rounded"
                    />
                    <span className="text-gray-300">Admin</span>
                  </label>
                </div>
              </div>

              <div className="flex justify-end space-x-3 mt-6">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setEditingUser(null)}
                  className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center gap-2"
                >
                  <XCircle size={20} /> Cancel
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={updateUser}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-500 transition-colors flex items-center gap-2"
                >
                  <Save size={20} /> Save Changes
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmDeleteUser}
        itemType="user"
      />
    </div>
  );
};

export default UserAll;
