import { API_URL } from "components/api_config";
import { PromoCodeType } from "components/PromoCode";
import { motion } from "framer-motion";
import { Box, Edit, Monitor, Wallet } from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";

interface PromoCode {
  id: number;
  code: string;
  value: number;
  usage: number;
  type: PromoCodeType;
  status: 'active' | 'used' | 'expired';
  requirements: string;
  boxSlug?: string;
  usedBy?: string;
  usedAt?: Date;
  createdAt: Date;
}

const ActiveCodeDisplay: React.FC<{ 
    code: PromoCode | null; 
    showOverlay: boolean;
    onCodeUpdate: () => void;
  }> = ({ code, showOverlay, onCodeUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedCode, setEditedCode] = useState('');
  
    const handleEditSubmit = async () => {
      if (!code || !editedCode) return;
  
      try {
        const response = await fetch(`${API_URL}/current-code/edit`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            currentCode: code.code,
            newCode: editedCode
          }),
        });
  
        if (!response.ok) throw new Error('Failed to update code');
  
        toast.success('Code updated successfully');
        setIsEditing(false);
        onCodeUpdate();
      } catch (error) {
        toast.error('Failed to update code');
        console.error('Error updating code:', error);
      }
    };
  
    if (!code) return null;
  
    return (
      <div className="mb-8">
        <motion.div 
          className="relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
        >
          <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-violet-950/50 to-gray-900/50 p-8 backdrop-blur-xl border border-gray-800/50">
            <div className="absolute inset-0 bg-grid-white/[0.02] -z-10" />
            <div className="absolute top-4 right-4 flex items-center space-x-4">
              <button
                onClick={() => {
                  setIsEditing(true);
                  setEditedCode(code.code);
                }}
                className="text-violet-400 hover:text-violet-300 transition-colors"
              >
                <Edit size={16} />
              </button>
              <div className={`w-2 h-2 rounded-full mr-2 ${showOverlay ? 'bg-emerald-500 animate-pulse' : 'bg-red-500'}`}></div>
              <span className="text-sm font-medium text-gray-400">
                {showOverlay ? 'Active' : 'Hidden'}
              </span>
            </div>
  
            <div className="flex items-center justify-center gap-2 mb-4">
              <div className="p-3 bg-violet-500/10 rounded-xl">
                <Monitor className="text-violet-400 w-5 h-5" />
              </div>
              <h2 className="text-lg font-bold text-white">Active Code</h2>
            </div>
  
            {isEditing ? (
              <div className="flex items-center gap-2 justify-center mb-4">
                <input
                  type="text"
                  value={editedCode}
                  onChange={(e) => setEditedCode(e.target.value)}
                  className="text-2xl font-bold bg-violet-500/10 border-2 border-violet-500/30 rounded-xl px-4 py-2 text-center text-violet-400 focus:outline-none focus:border-violet-500"
                  autoFocus
                />
                <motion.button
                  onClick={handleEditSubmit}
                  className="bg-violet-500 text-white px-4 py-2 rounded-xl hover:bg-violet-600"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Save
                </motion.button>
                <motion.button
                  onClick={() => setIsEditing(false)}
                  className="bg-gray-700 text-white px-4 py-2 rounded-xl hover:bg-gray-600"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Cancel
                </motion.button>
              </div>
            ) : (
              <motion.div 
                className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-violet-400 to-violet-600 mb-4 tracking-wider font-mono text-center"
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ type: "spring", stiffness: 200, damping: 10 }}
              >
                {code.code}
              </motion.div>
            )}
  
            <div className="flex items-center justify-center gap-3 text-sm">
              <div className="flex items-center bg-violet-500/10 px-3 py-1.5 rounded-lg">
                {code.type === 'Deposit Bonus' && <Wallet size={16} className="mr-2 text-violet-400" />}
                {code.type === 'Free Box' && <Box size={16} className="mr-2 text-violet-400" />}
                {code.type === 'Balance' && <Wallet size={16} className="mr-2 text-violet-400" />}
                <span className="text-violet-400">{code.type}</span>
              </div>
              <div className="text-xl font-bold text-white bg-white/5 px-4 py-1.5 rounded-lg">
                {code.type === 'Deposit Bonus' ? `${code.value}%` : `${code.value}€`}
              </div>
            </div>
  
            {code.usage > 0 && (
              <div className="mt-3 text-center">
                <span className="bg-violet-500/10 px-3 py-1 rounded-full text-sm text-violet-400">
                  Max usage: {code.usage}
                </span>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    );
  };
  
export default ActiveCodeDisplay;