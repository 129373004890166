import { motion } from "framer-motion";
import { Search } from "lucide-react";
import { useEffect, useState } from "react";

export interface BoxSelectorProps {
  onSelect: (box: Box) => void;
}

interface Box {
    name: string;
    slug: string;
    image: string;
    price: number;
    categories: { name: string; slug: string; type: string; }[];
  }

export const BoxSelector: React.FC<BoxSelectorProps> = ({ onSelect }) => {
  const [boxes, setBoxes] = useState<Box[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const fetchBoxes = async () => {
    try {
      const response = await fetch('https://api.empiredrop.com/api/v1/boxes/search', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          brands: [],
          categories: [],
          order: 'CHEAPEST',
          price_max: 500000,
          price_min: 0,
          tags: []
        })
      });

      const data = await response.json();
      const formattedBoxes = data.map((box: any) => ({
        name: box.name,
        slug: box.slug,
        image: box.image.startsWith('/') ? `https://cdn.empiredrop.com${box.image}` : `https://cdn.empiredrop.com/${box.image}`,
        price: box.price,
        categories: box.categories || []
      }));
      setBoxes(formattedBoxes);
    } catch (error) {
      console.error('Error fetching boxes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBoxes();
  }, []);

  const filteredBoxes = boxes.filter(box =>
    box.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-4">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]" size={20} />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full bg-[#1A1C23] text-white border-2 border-[#3D4149] rounded-xl py-3 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-colors"
          placeholder="Search boxes..."
        />
      </div>

      <div className="max-h-[300px] overflow-y-auto custom-scrollbar space-y-2">
        {isLoading ? (
          <div className="flex justify-center items-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#611BE2]"></div>
          </div>
        ) : (
          filteredBoxes.map((box) => (
            <motion.div
              key={box.slug}
              className="flex items-center space-x-4 p-3 bg-[#1A1C23] rounded-xl border border-[#3D4149] hover:border-[#611BE2] cursor-pointer transition-all"
              onClick={() => onSelect(box)}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <img 
                src={box.image} 
                alt={box.name} 
                className="w-12 h-12 object-cover rounded-lg"
              />
              <div className="flex-1">
                <div className="text-white font-medium">{box.name}</div>
                <div className="text-sm text-gray-400">
                  {box.categories.map(cat => cat.name).join(', ')}
                </div>
              </div>
              <div className="text-[#611BE2] font-bold">
                {(box.price / 100).toFixed(2)}€
              </div>
            </motion.div>
          ))
        )}
      </div>
    </div>
  );
};

export default BoxSelector;