import { Power, RotateCw } from "lucide-react";
import { useEffect, useRef, useState } from "react";

const SystemTab: React.FC = () => {
    const [status, setStatus] = useState<'online' | 'offline' | 'warning'>("online");
    const [consoleOutput, setConsoleOutput] = useState<string[]>([
        "[INFO] System initialized",
    ]);
    const [command, setCommand] = useState("");
    const socketRef = useRef<WebSocket | null>(null);

    useEffect(() => {
        socketRef.current = new WebSocket("ws://localhost:8100/ws");

        socketRef.current.onopen = () => {
            setConsoleOutput(prev => [...prev, "[INFO] Connected to SSH server"]);
        };

        socketRef.current.onmessage = (event) => {
            setConsoleOutput(prev => [...prev, event.data]);
        };

        socketRef.current.onerror = (error) => {
            setConsoleOutput(prev => [...prev, "[ERROR] WebSocket error"]);
            console.error("WebSocket Error:", error);
        };

        return () => {
            socketRef.current?.close();
        };
    }, []);

    const sendCommand = () => {
        if (socketRef.current && command.trim() !== "") {
            socketRef.current.send(command);
            setConsoleOutput(prev => [...prev, `> ${command}`]);
            setCommand("");
        }
    };

    const getStatusColor = (status: 'online' | 'offline' | 'warning') => {
        switch (status) {
            case "online": return "bg-emerald-500";
            case "offline": return "bg-red-500";
            case "warning": return "bg-yellow-500";
        }
    };

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between p-4 bg-gray-800/50 rounded-lg border border-gray-700">
                <div className="flex items-center gap-3">
                    <div className={`w-3 h-3 rounded-full ${getStatusColor(status)} animate-pulse`} />
                    <span className="text-gray-200">System Status: {status}</span>
                </div>

                <div className="flex gap-3">
                    <button className="px-4 py-2 bg-emerald-600 hover:bg-emerald-700 rounded-lg flex items-center gap-2" onClick={() => setStatus("online")}>
                        <Power size={16} /> Start
                    </button>
                    <button className="px-4 py-2 bg-red-600 hover:bg-red-700 rounded-lg flex items-center gap-2" onClick={() => setStatus("offline")}>
                        <Power size={16} /> Stop
                    </button>
                    <button className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg flex items-center gap-2" onClick={() => setStatus("warning")}>
                        <RotateCw size={16} /> Restart
                    </button>
                </div>
            </div>

            <div className="h-[400px] bg-black rounded-lg border border-gray-700 p-4 font-mono text-sm text-green-400 overflow-auto">
                {consoleOutput.map((line, i) => (
                    <div key={i} className="mb-1">{line}</div>
                ))}
            </div>

            <div className="flex gap-3">
                <input
                    type="text"
                    className="flex-1 p-2 bg-gray-900 text-white rounded-lg border border-gray-700"
                    placeholder="Enter command..."
                    value={command}
                    onChange={(e) => setCommand(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && sendCommand()}
                />
                <button className="px-4 py-2 bg-green-600 hover:bg-green-700 rounded-lg" onClick={sendCommand}>
                    Send
                </button>
            </div>
        </div>
    );
};

export default SystemTab;
