import React, { useEffect, useState } from "react";
import {
  Calendar,
  Clock,
  Plus,
  Check,
  X,
  Edit2,
  Trash2,
  User,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import axios from "axios";
import { API_URL } from "components/api_config";

interface Task {
  id: number;
  title: string;
  description: string;
  type: "task" | "idea" | "stream";
  date: string;
  assignedTo: string[];
  completed: boolean;
  priority: "low" | "medium" | "high";
  user: { username: string };
  userId: number;
  createdAt: string;
  updatedAt: string;
}
interface TaskStats {
  total: number;
  tasks: number;
  ideas: number;
  completed: number;
  upcoming: number;
}
interface User {
  id: number;
  username: string;
  pfp: string | null;
  role: string;
}

interface PaginationMeta {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

const UserSelector: React.FC<{
  users: User[];
  selectedUsers: string[];
  onChange: (values: string[]) => void;
}> = ({ users, selectedUsers, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleUser = (username: string) => {
    const updatedSelection = selectedUsers.includes(username)
      ? selectedUsers.filter((u) => u !== username)
      : [...selectedUsers, username];
    onChange(updatedSelection);
  };

  return (
    <div className="space-y-2 mb-4">
      <label className="block text-sm font-medium text-gray-300">
        Assign Users
      </label>
      <div className="relative">
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="w-full bg-gray-800/50 border border-gray-700 rounded-xl px-4 py-3 
            text-white cursor-pointer hover:bg-gray-800/70 transition-colors duration-200"
        >
          {selectedUsers.length === 0 ? (
            <span className="text-gray-400">Select users...</span>
          ) : (
            <div className="flex flex-wrap gap-2">
              {selectedUsers.map((username) => (
                <span
                  key={username}
                  className="inline-flex items-center px-2 py-1 rounded-lg bg-violet-500/20 text-violet-400 text-sm"
                >
                  <User size={14} className="mr-1" />
                  {username}
                </span>
              ))}
            </div>
          )}
        </div>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="absolute z-50 w-full mt-2 bg-gray-900 border border-gray-700 
                rounded-xl shadow-xl overflow-hidden max-h-60 overflow-y-auto"
            >
              <div className="bg-gray-900">
                {users.map((user) => (
                  <div
                    key={user.id}
                    onClick={() => toggleUser(user.username)}
                    className={`flex items-center gap-3 p-3 cursor-pointer transition-colors duration-200
                      ${
                        selectedUsers.includes(user.username)
                          ? "bg-violet-500/20"
                          : "hover:bg-gray-800"
                      }`}
                  >
                    <div className="w-8 h-8 rounded-lg bg-violet-500/10 flex items-center justify-center">
                      <User size={16} className="text-violet-400" />
                    </div>
                    <div>
                      <div className="text-white font-medium">
                        {user.username}
                      </div>
                      <div className="text-gray-400 text-sm">{user.role}</div>
                    </div>
                    {selectedUsers.includes(user.username) && (
                      <Check size={16} className="ml-auto text-violet-400" />
                    )}
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const toUTC = (date: Date): string => {
  return date.toISOString().slice(0, 19).replace("T", " ");
};

const fromUTC = (dateStr: string): Date => {
  return new Date(dateStr + "Z");
};

const formatDateTime = (dateStr: string) => {
  const date = new Date(dateStr);
  date.setHours(date.getHours() + 1);
  return {
    date: date.toLocaleDateString("en-US"),
    time: `${date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })} (UTC)`,
  };
};

const getLocalDateTime = (date: string) => {
  const d = new Date(date);
  const offset = d.getTimezoneOffset();
  return new Date(d.getTime() - offset * 60 * 1000);
};

// Update formatTime function
const formatTime = (dateStr: string): string => {
  const date = fromUTC(dateStr);
  return `${date.toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })} (UTC)`;
};

const getCategoryColor = (category: string) => {
  switch (category) {
    case "stream":
      return "bg-purple-500/20 text-purple-400";
    case "planning":
      return "bg-blue-500/20 text-blue-400";
    case "event":
      return "bg-amber-500/20 text-amber-400";
    default:
      return "bg-violet-500/20 text-violet-400";
  }
};

const PlanningCalendar: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const [stats, setStats] = useState<TaskStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<PaginationMeta | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [showAddTask, setShowAddTask] = useState(false);
  const [newTask, setNewTask] = useState<{
    title: string;
    description: string;
    type: "task" | "idea" | "stream";
    date: string;
    time: string;
    assignedTo: string[];
  }>({
    title: "",
    description: "",
    type: "task",
    date: selectedDate.toISOString().slice(0, 19).replace("T", " "),
    time: "00:00",
    assignedTo: [],
  });
  const [editingTask, setEditingTask] = useState<Task | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_URL}/all-profile`);
        setUsers(response.data);
      } catch (error) {
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, []);

  const handleEditTask = async (taskId: number, updates: Partial<Task>) => {
    try {
      const response = await axios.put(
        `${API_URL}/planning/${taskId}`,
        updates
      );
      setTasks(
        tasks.map((task) => (task.id === taskId ? response.data.data : task))
      );
      setEditingTask(null);
      toast.success("Task updated successfully");
      fetchTasks();
    } catch (error) {
      toast.error("Failed to update task");
    }
  };

  useEffect(() => {
    setNewTask((prev) => ({
      ...prev,
      date: selectedDate.toISOString().slice(0, 19).replace("T", " "),
    }));
  }, [selectedDate]);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/planning?page=${page}&limit=10`
      );
      const tasksWithAssignedTo = response.data.data.map((task: any) => ({
        ...task,
        assignedTo: JSON.parse(task.assignedTo),
      }));
      setTasks(tasksWithAssignedTo);
      setMeta(response.data.meta);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast.error("Failed to fetch tasks");
    }
  };

  const fetchStats = async () => {
    try {
      const response = await axios.get(API_URL + "/planning/stats");
      setStats(response.data.data);
    } catch (error) {
      toast.error("Failed to fetch stats");
    }
  };

  const getTaskCountStyle = (count: number) => {
    if (count === 0) return "";
    if (count <= 2) return "bg-blue-500/10";
    if (count <= 4) return "bg-blue-500/20";
    return "bg-blue-500/30";
  };

  const getTaskCountForDate = (date: Date) => {
    return tasks.filter(
      (task) => new Date(task.date).toDateString() === date.toDateString()
    ).length;
  };

  const renderDay = (date: Date) => {
    const taskCount = getTaskCountForDate(date);
    const tasksForDay = tasks.filter((task) => {
      const taskDate = getLocalDateTime(task.date);
      return taskDate.toDateString() === date.toDateString();
    });
    const isSelected = selectedDate.toDateString() === date.toDateString();
    const isToday = new Date().toDateString() === date.toDateString();

    return (
      <motion.div
        onClick={() => setSelectedDate(date)}
        className={`relative w-full h-full min-h-[80px] p-2 rounded-lg cursor-pointer
          ${getTaskCountStyle(taskCount)}
          ${isSelected ? "ring-2 ring-violet-500" : ""}
          ${isToday ? "border border-violet-500" : ""}`}
        whileHover={{ scale: 1.02 }}
      >
        <span className="text-gray-400 text-sm">{date.getDate()}</span>
        {taskCount > 0 && (
          <div className="absolute top-2 right-2">
            <span
              className="flex items-center justify-center w-5 h-5 text-xs 
              font-medium text-white bg-violet-500 rounded-full"
            >
              {taskCount}
            </span>
          </div>
        )}
        {tasksForDay.length > 0 && (
          <div className="mt-2 space-y-1">
            {tasksForDay.slice(0, 2).map((task) => (
              <div key={task.id} className="text-xs text-gray-400 truncate">
                {formatDateTime(task.date).time} - {task.title}
              </div>
            ))}
          </div>
        )}
      </motion.div>
    );
  };

  const addTask = async () => {
    if (!newTask.title.trim()) return;

    try {
      const taskDate = new Date(selectedDate);
      const [hours, minutes] = newTask.time.split(":");
      taskDate.setHours(parseInt(hours), parseInt(minutes));

      const taskToAdd = {
        ...newTask,
        date: toUTC(taskDate),
        assignedTo: selectedUsers,
      };

      await axios.post(API_URL + "/planning", taskToAdd);
      setSelectedUsers([]);
      await fetchTasks();
      setShowAddTask(false);
      toast.success("Task added successfully");
    } catch (error) {
      toast.error("Failed to add task");
    }
  };
  const deleteTask = async (taskId: number) => {
    try {
      await axios.delete(`${API_URL}/planning/${taskId}`);
      setTasks(tasks.filter((task) => task.id !== taskId));
      toast.success("Task deleted successfully");
      fetchStats();
    } catch (error) {
      toast.error("Failed to delete task");
    }
  };

  const updateTask = async (taskId: number, updates: Partial<Task>) => {
    try {
      const response = await axios.put(
        `${API_URL}/planning/tasks/${taskId}`,
        updates
      );
      setTasks(
        tasks.map((task) => (task.id === taskId ? response.data.data : task))
      );
      toast.success("Task updated successfully");
      fetchStats();
    } catch (error) {
      toast.error("Failed to update task");
    }
  };

  const getDaysInMonth = (date: Date): number => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0).getDate();
  };

  const getMonthDays = (): (number | null)[] => {
    const days: (number | null)[] = [];
    const firstDay = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1
    ).getDay();
    const daysInMonth = getDaysInMonth(selectedDate);

    const adjustedFirstDay = firstDay === 0 ? 6 : firstDay - 1;

    for (let i = 0; i < adjustedFirstDay; i++) {
      days.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }

    return days;
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const previousMonth = (): void => {
    setSelectedDate(
      new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1)
    );
  };

  const nextMonth = (): void => {
    setSelectedDate(
      new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1)
    );
  };
  useEffect(() => {
    fetchTasks();
    fetchStats();
  }, [page]);

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-violet-900/20 via-gray-900 to-black p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-violet-950/50 to-gray-900/50 p-8 backdrop-blur-xl border border-gray-800/50 mb-8">
          <div className="absolute inset-0 bg-grid-white/[0.02] -z-10" />
          <div className="flex items-center gap-4">
            <div className="p-3 bg-violet-500/10 rounded-2xl ring-1 ring-violet-500/20">
              <Calendar className="w-8 h-8 text-violet-400" />
            </div>
            <div>
              <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                Planning Calendar
              </h1>
              <p className="text-gray-400 text-sm mt-1">
                Manage Stream and Tasks easily
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Calendar */}
          <div className="lg:col-span-2">
            <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
              <div className="flex justify-between items-center mb-6">
                <div className="flex items-center gap-4">
                  <select
                    value={selectedDate.getMonth()}
                    onChange={(e) => {
                      const newDate = new Date(selectedDate);
                      newDate.setMonth(parseInt(e.target.value));
                      setSelectedDate(newDate);
                    }}
                    className="bg-gray-800/50 rounded-lg px-3 py-2 text-white border border-gray-700"
                  >
                    {months.map((month, index) => (
                      <option key={month} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>

                  <select
                    value={selectedDate.getFullYear()}
                    onChange={(e) => {
                      const newDate = new Date(selectedDate);
                      newDate.setFullYear(parseInt(e.target.value));
                      setSelectedDate(newDate);
                    }}
                    className="bg-gray-800/50 rounded-lg px-3 py-2 text-white border border-gray-700"
                  >
                    {Array.from(
                      { length: 10 },
                      (_, i) => new Date().getFullYear() - 2 + i
                    ).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex gap-2">
                  <motion.button
                    onClick={previousMonth}
                    className="p-2 hover:bg-violet-500/20 rounded-lg text-white"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    ←
                  </motion.button>
                  <motion.button
                    onClick={nextMonth}
                    className="p-2 hover:bg-violet-500/20 rounded-lg text-white"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    →
                  </motion.button>
                </div>
              </div>{" "}
              <div className="grid grid-cols-7 gap-2 mb-2">
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                  (day) => (
                    <div
                      key={day}
                      className="text-center text-gray-400 text-sm"
                    >
                      {day}
                    </div>
                  )
                )}
              </div>
              <div className="grid grid-cols-7 gap-2">
                {getMonthDays().map((day, index) => (
                  <motion.div
                    key={index}
                    className={`aspect-square flex items-center justify-center rounded-lg ${
                      day ? "hover:bg-violet-500/20 cursor-pointer" : ""
                    }`}
                    onClick={() =>
                      day &&
                      setSelectedDate(
                        new Date(
                          selectedDate.getFullYear(),
                          selectedDate.getMonth(),
                          day
                        )
                      )
                    }
                  >
                    {day
                      ? renderDay(
                          new Date(
                            selectedDate.getFullYear(),
                            selectedDate.getMonth(),
                            day
                          )
                        )
                      : null}
                  </motion.div>
                ))}
              </div>
            </div>
          </div>

          {/* Tasks */}
          <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl text-white font-semibold">Task</h2>
              <motion.button
                onClick={() => setShowAddTask(true)}
                className="p-2 bg-violet-500/20 rounded-lg text-violet-400"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Plus size={20} />
              </motion.button>
            </div>
            <AnimatePresence>
              {editingTask && (
                <motion.div
                  className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <motion.div
                    className="bg-gray-900/90 rounded-xl p-6 w-full max-w-md"
                    initial={{ scale: 0.95, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.95, opacity: 0 }}
                  >
                    <h3 className="text-xl font-semibold text-white mb-4">
                      Edit Task
                    </h3>
                    <input
                      type="text"
                      value={editingTask.title}
                      onChange={(e) =>
                        setEditingTask({
                          ...editingTask,
                          title: e.target.value,
                        })
                      }
                      className="w-full bg-gray-800/50 rounded-lg p-2 mb-2 text-white"
                    />
                    <textarea
                      value={editingTask.description}
                      onChange={(e) =>
                        setEditingTask({
                          ...editingTask,
                          description: e.target.value,
                        })
                      }
                      className="w-full bg-gray-800/50 rounded-lg p-2 mb-2 text-white"
                    />
                    <select
                      value={editingTask.type}
                      onChange={(e) =>
                        setEditingTask({
                          ...editingTask,
                          type: e.target.value as Task["type"],
                        })
                      }
                      className="w-full bg-gray-800/50 rounded-lg p-2 mb-4 text-white"
                    >
                      <option value="task">Task</option>
                      <option value="idea">Idea</option>
                      <option value="stream">Stream</option>
                    </select>

                    <input
                      type="time"
                      value={formatTime(editingTask.date)}
                      onChange={(e) => {
                        const [hours, minutes] = e.target.value.split(":");
                        const newDate = new Date(editingTask.date);
                        newDate.setHours(parseInt(hours), parseInt(minutes));
                        setEditingTask({
                          ...editingTask,
                          date: newDate
                            .toISOString()
                            .slice(0, 19)
                            .replace("T", " "),
                        });
                      }}
                      className="w-full bg-gray-800/50 rounded-lg p-2 mb-4 text-white"
                    />
                    <div className="space-y-2 mb-4">
                      <label className="block text-sm font-medium text-gray-300">
                        Assigned Users
                      </label>
                      <select
                        multiple
                        value={editingTask.assignedTo || []}
                        onChange={(e) => {
                          const values = Array.from(
                            e.target.selectedOptions,
                            (option) => option.value
                          );
                          setEditingTask({
                            ...editingTask,
                            assignedTo: values,
                          });
                        }}
                        className="w-full bg-gray-800/50 border border-gray-700 rounded-xl px-4 py-3 
      text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                      >
                        {users.map((user) => (
                          <option key={user.id} value={user.username}>
                            {user.username} ({user.role})
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex justify-end gap-2">
                      <motion.button
                        onClick={() => setEditingTask(null)}
                        className="p-2 hover:bg-red-500/20 rounded-lg text-red-400"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <X size={20} />
                      </motion.button>
                      <motion.button
                        onClick={() =>
                          handleEditTask(editingTask.id, editingTask)
                        }
                        className="p-2 hover:bg-emerald-500/20 rounded-lg text-emerald-400"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <Check size={20} />
                      </motion.button>
                    </div>
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {showAddTask && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="mb-6 bg-black/20 rounded-lg p-4"
                >
                  <input
                    type="text"
                    placeholder="Title"
                    className="w-full bg-gray-800/50 rounded-lg p-2 mb-2 text-white"
                    value={newTask.title}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setNewTask({ ...newTask, title: e.target.value })
                    }
                  />
                  <textarea
                    placeholder="Description"
                    className="w-full bg-gray-800/50 rounded-lg p-2 mb-2 text-white"
                    value={newTask.description}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setNewTask({ ...newTask, description: e.target.value })
                    }
                  />
                  <select
                    className="w-full bg-gray-800/50 rounded-lg p-2 mb-4 text-white"
                    value={newTask.type}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setNewTask({
                        ...newTask,
                        type: e.target.value as "task" | "idea" | "stream",
                      })
                    }
                  >
                    <option value="task">Task</option>
                    <option value="idea">Idea</option>
                    <option value="stream">Stream</option>
                  </select>
                  <style>
                    {`
    input[type="time"]::-webkit-calendar-picker-indicator {
      filter: invert(1) hue-rotate(180deg);
      cursor: pointer;
      opacity: 0.6;
    }
    
    input[type="time"]::-webkit-calendar-picker-indicator:hover {
      opacity: 1;
    }
  `}
                  </style>

                  <div className="flex gap-4 mb-4">
                    <input
                      type="time"
                      className="w-full bg-gray-800/50 rounded-lg p-2 text-white appearance-none"
                      value={newTask.time}
                      onChange={(e) =>
                        setNewTask({
                          ...newTask,
                          time: e.target.value,
                        })
                      }
                    />
                  </div>
                  <UserSelector
                    users={users}
                    selectedUsers={selectedUsers}
                    onChange={setSelectedUsers}
                  />
                  <div className="flex justify-end gap-2">
                    <motion.button
                      onClick={() => setShowAddTask(false)}
                      className="p-2 hover:bg-red-500/20 rounded-lg text-red-400"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <X size={20} />
                    </motion.button>
                    <motion.button
                      onClick={addTask}
                      className="p-2 hover:bg-emerald-500/20 rounded-lg text-emerald-400"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <Check size={20} />
                    </motion.button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <div className="space-y-4">
              <AnimatePresence>
                {tasks
                  .filter((task) => {
                    const taskDate = new Date(task.date);
                    return (
                      taskDate.getDate() === selectedDate.getDate() &&
                      taskDate.getMonth() === selectedDate.getMonth() &&
                      taskDate.getFullYear() === selectedDate.getFullYear()
                    );
                  })
                  .map((task) => (
                    <motion.div
                      key={task.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      className="bg-black/20 rounded-lg p-4"
                    >
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="text-white font-medium">
                            {task.title}
                          </h3>
                          <div className="flex items-center gap-2 text-gray-400 text-sm mt-1">
                            <Clock size={14} color="violet" />
                            {formatDateTime(task.date).time}
                          </div>

                          <p className="text-gray-400 text-sm mt-1">
                            {task.description}
                          </p>

                          <div className="flex flex-wrap gap-2 mt-2">
                            <span className="px-2 py-1 text-xs rounded-full bg-violet-500/20 text-violet-400">
                              {task.type}
                            </span>

                            {task.assignedTo && task.assignedTo.length > 0 && (
                              <>
                                {task.assignedTo.map((username: string) => (
                                  <span
                                    key={username}
                                    className="px-2 py-1 text-xs rounded-full bg-blue-500/20 text-blue-400 flex items-center gap-1"
                                  >
                                    <User size={12} />
                                    {username}
                                  </span>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="flex gap-2">
                          <motion.button
                            onClick={() => deleteTask(task.id)}
                            className="p-2 hover:bg-red-500/20 rounded-lg text-red-400"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <Trash2 size={16} />
                          </motion.button>
                          <motion.button
                            onClick={() => setEditingTask(task)}
                            className="p-2 hover:bg-violet-500/20 rounded-lg text-violet-400"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <Edit2 size={16} />
                          </motion.button>
                        </div>
                      </div>
                    </motion.div>
                  ))}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanningCalendar;
