import React, { useEffect, useState, CSSProperties } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Home, Gem, FerrisWheel, Box, Ticket, Server, Calendar, ChevronLeft, ChevronRight, ChevronDown, Users, Settings, Bot, Mail, ChartBarIcon, Sheet, AlignVerticalJustifyStartIcon, HeartHandshake, Trophy } from 'lucide-react';
import { API_URL } from 'components/api_config';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

interface NavItemProps {
  icon: React.ElementType;
  label: string;
  to: string;
  badge?: string;
  badgeColor?: string;
  isOpen: boolean;
}

interface UserProfile {
  id: number;
  username: string;
  email: string;
  pfp: string | null;
  isAdmin: boolean | null;
  kickUrl: string;
  confirmed: boolean;
  createdAt: string;
  updatedAt: string;
}

interface CategoryProps {
  title: string;
  items: {
    icon: React.ElementType;
    label: string;
    to: string;
    badge?: string;
  }[];
  isOpen: boolean;
}

const scrollbarStyles = {
  container: {
    scrollbarWidth: 'thin' as CSSProperties['scrollbarWidth'],
    scrollbarColor: '#611BE2 #1A1C23',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#1A1C23',
      borderRadius: '3px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#611BE2',
      borderRadius: '3px',
      transition: 'background-color 0.3s ease'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#8B5CF6'
    }
  } as CSSProperties
} as const;

const initialNavCategories = [
  {
    title: "Dashboard",
    items: [
      { icon: Home, label: "Home", to: "/" }
    ]
  },
  {
    title: "Main",
    items: [
      { icon: FerrisWheel, label: "Draw", to: "/draw" },
      { icon: Sheet, label: "Bingo", to: "/bingo" },
      { icon: Ticket, label: "Promo Code", to: "/promo-code" }
    ]
  },
  {
    title: "Empire Deal",
    items: [
      { icon: Gem, label: "Prize Editor", to: "/prize" },
      { icon: Box, label: "Random Boxes", to: "/boxes" },
    ]
  },

  {
    title: "Misc",
    items: [
      { icon: ChartBarIcon, label: "Links", to: "/link" },
      { icon: Calendar, label: "Planning", to: "/planning" },
      { icon: HeartHandshake, label: "Viewers", to: "/viewers" },
      { icon: Trophy, label: "Winners", to: "/winners" }
    ]
  }
];

const adminCategory = {
  title: "Admin",
  items: [
    { icon: Users, label: "Users", to: "/admin/users" },
    { icon: Mail, label: "Email Whitelist", to: "/admin/email" },
    { icon: Ticket, label: "Promo Code Admin", to: "/admin/promo-code" },
    { icon: Bot, label: "Bot Settings", to: "/admin/bot-settings" },
    { icon: Server, label: "Server Status", to: "/status", badge: "46% UP" },
  ]
};

const NavItem: React.FC<NavItemProps> = ({ 
  icon: Icon, 
  label, 
  to,
  badge,
  badgeColor = "bg-[#611BE2]",
  isOpen
}) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <motion.li
      className="mb-2"
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
      <Link
        to={to}
        className={`flex items-center justify-between p-3 rounded-lg transition-all duration-300 ease-in-out hover:bg-[#1A1C23] group cursor-pointer ${isActive ? 'bg-[#1A1C23]' : ''}`}
      >
        <div className="flex items-center">
          <motion.div
            className={`w-10 h-10 flex items-center justify-center bg-[#1A1C23] rounded-lg group-hover:bg-[#611BE2] transition-all duration-300 ${isActive ? 'bg-[#611BE2]' : ''}`}
            whileHover={{ rotate: 360 }}
            transition={{ duration: 0.5 }}
          >
            <Icon className={`w-5 h-5 text-[#8B5CF6] group-hover:text-white ${isActive ? 'text-white' : ''}`} />
          </motion.div>
          {isOpen && (
            <motion.span 
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -10 }}
              transition={{ duration: 0.3 }}
              className={`ml-3 font-medium group-hover:text-white ${isActive ? 'text-white' : 'text-gray-300'}`}
            >
              {label}
            </motion.span>
          )}
        </div>
        {isOpen && badge && (
          <motion.span
            className={`text-xs px-2 py-1 rounded-full ${badgeColor} text-white font-semibold`}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            {badge}
          </motion.span>
        )}
      </Link>
    </motion.li>
  );
};

const Category: React.FC<CategoryProps> = ({ title, items, isOpen }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  return (
    <div className="space-y-2">
      {isOpen && (
        <motion.div 
          className="flex items-center justify-between px-3 cursor-pointer"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <motion.h2 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="text-gray-400 text-xs uppercase font-semibold"
          >
            {title}
          </motion.h2>
          <motion.div
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <ChevronDown className="w-4 h-4 text-gray-400" />
          </motion.div>
        </motion.div>
      )}
      <AnimatePresence>
        {isExpanded && (
          <motion.ul
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            {items.map((item, itemIndex) => (
              <NavItem
                key={itemIndex}
                icon={item.icon}
                label={item.label}
                to={item.to}
                badge={item.badge}
                isOpen={isOpen}
              />
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [navCategories, setNavCategories] = useState(initialNavCategories);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(API_URL +  '/profile', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (response.ok) {
          const data = await response.json();
          setProfile(data);
          
          if (data.isAdmin === true || data.isAdmin === 1) {
            setNavCategories(prev => {
              const adminExists = prev.some(category => category.title === "Admin");
              if (!adminExists) {
                return [...prev, adminCategory];
              }
              return prev;
            });
          }
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  return (
    <motion.nav
      className="bg-gradient-to-b from-[#0E1015] to-[#1A1C23] h-full overflow-hidden flex-shrink-0 relative"
      initial={false}
      animate={{ 
        width: isOpen ? 280 : 80,
        transition: { duration: 0.3, ease: "easeInOut" }
      }}
    >
      <div className="flex flex-col h-full">
        <motion.div 
          className="p-4 flex justify-between items-center"
          initial={false}
          animate={{ 
            paddingLeft: isOpen ? 16 : 24,
            transition: { duration: 0.3, ease: "easeInOut" }
          }}
        >
          <AnimatePresence>
            {isOpen && (
              <motion.h1 
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
                className="text-white font-bold text-xl"
              >
                {profile?.username || 'Dashboard'}
              </motion.h1>
            )}
          </AnimatePresence>
          <motion.button
            className="text-gray-400 hover:text-white p-2 rounded-full bg-[#1A1C23] hover:bg-[#611BE2] focus:outline-none transition-colors duration-300"
            onClick={toggleSidebar}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {isOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
          </motion.button>
        </motion.div>

        <div 
          className="flex-1 px-3 py-4 space-y-6 overflow-y-auto"
          style={scrollbarStyles.container}
        >
          {navCategories.map((category, index) => (
            <Category
              key={index}
              title={category.title}
              items={category.items}
              isOpen={isOpen}
            />
          ))}
        </div>
      </div>
    </motion.nav>
  );
};

export default Sidebar;